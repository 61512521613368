@use "../../styles/variables.scss";

.nav-carousel {
  h2 {
    @include variables.line-height(38, 30);
    font-size: 3rem;
    margin-bottom: 0;
    padding-bottom: 2.4rem;
    background-color: variables.$projectBone;
  }

  .swiper {
    margin-bottom: 40px;

    :global(.swiper-slide) {
      display: flex;
      justify-content: center;
      cursor: pointer;
      position: relative;
    }
  }
}

.lightbox {
  display: block;
  width: 80%;
  max-width: 1180px;
  padding: 6rem 12rem 4rem;
  background-color: variables.$projectWhite;
  height: 100%;
  max-height: 90vh;

  > div {
    height: 100%;
  }

  :global(.close) {
    z-index: 1000;
    top: 20px;
    right: 20px;
  }

  :global(.swiper) {
    width: 100%;
    height: 100%;
  }

  picture,
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .next,
  .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-40px);
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }
}

// MOBILE
@media #{variables.$mobile} {
  .lightbox {
    max-width: 39rem;
    padding: 6rem 0 2rem;
    width: auto;
    height: auto;

    .next,
    .prev {
      top: auto;
      bottom: -40px;
    }
  }
}

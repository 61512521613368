@import "../../styles/variables.scss";

.Wrapper {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.Ratio {
  width: 100%;
  height: 100%;
}

.Image {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: auto;
    transition: 0.3s;
  }
}

.img {
  min-width: 60px;
}

@use "../../styles/variables.scss";

// COMPONENT - Availability Selector

// DEFAULT CLASS - .availability-selector

.availability-selector {
  margin: 2rem 0;
  font-family: variables.$baseFontFamily;
  font-size: 1.6rem;
  color: variables.$projectBlue;
  line-height: 1.6;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .days-times {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.1rem 5rem;
    align-items: center;

    .day-time {
      display: contents;

      .day-name {
        grid-column: 1 / span 1;
        margin-right: 1rem;
      }

      .time-slot {
        grid-column: span 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .time-slot-label {
        margin-right: 0.5rem;
        color: variables.$projectBlue;
      }

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin: 0;
        border: 1px solid variables.$projectBlue;
        background-color: transparent;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;

        &:checked {
          background-color: variables.$projectBlue;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .availability-selector {
    font-size: 1.6rem;

    .days-times .day-time label {
      font-size: 1.6rem;
    }
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .availability-selector {
    font-size: 1.6rem;

    .days-times .day-time label {
      font-size: 1.6rem;
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .availability-selector {
    font-size: 1.6rem;

    .days-times .day-time {
      flex-direction: column;
      align-items: flex-start;

      .time-slot {
        margin-bottom: 0.5rem;
      }

      .time-slot-label {
        margin-right: 0.5rem;
      }

      input[type="checkbox"] {
        margin: 0.5rem 0;
      }
    }
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .availability-selector {
      font-size: 1.6rem;

      .days-times .day-time label {
        font-size: 1.6rem;
      }
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .availability-selector {
      font-size: 1.6rem;

      .days-times .day-time label {
        font-size: 1.6rem;
      }
    }
  }
}

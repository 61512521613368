@use "../../styles/variables.scss";
// COMPONENT - InteractApplicationConfirmation

// DEFAULT CLASS - .interact-confirmation

// CLASS VARIANTS -

// WIDE WEB

.paymentDetails {
  margin-top: 3.5rem;
  p {
    span {
      color: #001e62;
      font-family: "GT-Sectra-Fine-Bold";
      margin-right: 1rem;
    }
  }
}

.interact-confirmation {
  margin: 7rem 0 10rem;
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .interact-confirmation {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .interact-confirmation {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .interact-confirmation {
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
    .interact-confirmation {
    }
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
    .interact-confirmation {
    }
  }
}

@use "../../styles/variables.scss";

.donate-tool {
  background: variables.$baseFontColour;
  color: variables.$projectWhite;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
  padding: 11rem 2.5rem 4rem;

  .small-page-title {
    margin: 0 0 1.5rem;
    font-size: 1.4rem;
    font-family: variables.$baseFontFamily;
    text-transform: uppercase;
    display: block;
  }

  h3 {
    margin-bottom: 2.5rem;
  }

  .intro {
    max-width: 75rem;
    margin: 0 0 4.3rem;

    p {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  .form {
    .select-wrapper {
      display: flex;
      flex-direction: row;

      .select {
        margin-left: 4.8rem;
        margin-right: 4.4rem;

        select {
          border-color: variables.$projectDarkBone;
          color: variables.$projectBlue;
          font-size: 1.6rem;
          line-height: 2.4rem;

          option {
            &[disabled] {
              background-color: variables.$projectWhite;
              font-size: 1.2rem;
            }
          }

          optgroup {
            display: block;
          }
        }
      }

      .form-field {
        padding: 0;

        .message-wrapper {
          right: 0.8rem;
        }

        &[data-push-right] {
          .select {
            margin-left: 0;
            margin-right: 7.6rem;
          }

          .message-wrapper {
            right: 4rem;
          }
        }
      }
    }

    .btn-and-text-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .button-wrapper {
        margin: 2.5rem 0 2.3rem;
      }
    }
  }

  &-content {
    max-width: 75rem;
    margin: 0 auto 4.5rem;

    .intro {
      font-size: 1.6rem;
      text-align: center;
      max-width: 75rem;
      margin: 0 auto 4.4rem;
      line-height: 2.4rem;
    }
  }

  &.vertical-accordion {
    .accordion {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      > li {
        background-color: variables.$projectWhite;
        border-top-width: 0;
        color: variables.$baseFontColour;
        overflow: visible;
        width: 50%;

        &:first-child {
          border-right: 0.5rem solid variables.$baseFontColour;
        }

        &:last-child {
          border-bottom-width: 0;
          border-left: 0.5rem solid variables.$baseFontColour;

          .accordion-drop {
            left: calc(-100% - 1rem);
          }
        }

        > .toggle-link {
          background-color: variables.$projectBone;
          font-family: variables.$headingFontFamily;
          font-size: 3rem;
          padding: 2.2rem 3.6rem 2.1rem;
          font-weight: 400;
          display: block;
          position: relative;
          text-decoration: none;
          transition: all 0.5s ease-out;

          &::before {
            display: none;
          }

          &::after {
            content: "";
            position: absolute;
            width: 1.2rem;
            height: 1.8rem;
            background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
            right: 2.7rem;
            background-repeat: no-repeat;
            top: 40%;
            transform: rotate(90deg);
            background-color: transparent;
            transition: all 0.5s ease-out;
          }

          &[aria-expanded="true"] {
            background-color: variables.$projectWhite;

            &::after {
              background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
              transform: rotate(-90deg);
            }
          }
        }

        .accordion-drop {
          background-color: variables.$projectWhite;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          width: calc(200% + 1rem);
          position: relative;
          display: block;
          height: 0;
          padding: 0 2rem;

          &[aria-hidden="false"] {
            opacity: 1;
          }

          .content {
            padding: 3.7rem 4.6rem 0;
          }
        }
      }
    }

    .journey-content {
      &-step-one {
        display: block;
        padding-bottom: 4rem;

        .intro {
          margin-left: auto;
          margin-right: auto;
          font-size: 1.6rem;
          text-align: center;
          line-height: 2.4rem;
        }
      }

      &-step-two {
        display: none;
      }
    }

    &.show-journey-step-two {
      .journey-content {
        &-step-one {
          display: none;
        }

        &-step-two {
          display: block;
        }
      }

      .journey-step {
        .step-count {
          color: variables.$baseFontColour;

          span {
            color: variables.$baseFontColour;
          }

          &:first-child {
            &::after {
              border-color: variables.$projectRed;
            }

            a {
              cursor: pointer;
              opacity: 1;
              transition: opacity 0.25s ease-in-out;
              pointer-events: unset;

              &:hover,
              &:focus {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }

  // Inner accordion
  .donate-tool-vertical-accordion {
    background-color: variables.$projectWhite;
    margin-bottom: 2.4rem;
    padding: 0 2rem;
  }

  .journey-step {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4.4rem;

    .step-count {
      color: variables.$projectInteractiveNavy;
      font-family: variables.$baseFontFamily;
      font-size: 1.2rem;
      line-height: 2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        color: variables.$projectInteractiveNavy;
        font-family: variables.$headingFontFamily;
        font-size: 6rem;
        font-weight: bold;
        line-height: 8.4rem;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        pointer-events: none;
        font-weight: 500;
      }

      &:first-child {
        margin-right: 5rem;

        span {
          color: variables.$baseFontColour;
        }

        &::after {
          content: "";
          height: 1px;
          width: 96px;
          border: 3px solid variables.$projectInteractiveNavy;
          position: absolute;
          right: 0;
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
        }
      }

      &:last-child {
        margin-left: 5rem;
      }
    }
  }

  .cta-with-message {
    display: block;

    .message-wrapper {
      top: 2rem;
      bottom: auto;
    }
  }

  .cta-continue {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 22rem;
  }

  .donate-details {
    padding: 3.6rem;
    border: 0.1rem solid rgba(variables.$baseFontColour, 0.25);
    margin-bottom: 4rem;
    display: none;

    &.display-panel {
      display: block;
    }
  }

  address {
    font-style: normal;
    margin: 3rem 0;

    a {
      font-weight: 700;
    }
  }

  .donation-button-tab-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-bottom: 0.1rem solid variables.$baseFontColour;

    .cta-donate {
      background-color: transparent;
      border-width: 0;
      border-bottom: 0.2rem solid transparent;
      color: variables.$baseFontColour;
      display: none;
      flex: 1 1 auto;
      font-family: variables.$baseFontFamily;
      font-size: 3rem;
      font-weight: 500;
      padding: 1rem 2rem;
      text-align: center;
      transition: none;
      opacity: 0.5;
      position: relative;

      &.is--active {
        border-bottom: 0.2rem solid variables.$baseFontColour;
        opacity: 1;
      }

      &.has--form {
        display: block;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }
    }
  }

  .bbox-root {
    .BBFormContainer {
      // sass-lint:disable-line class-name-format
      &[data-bbox-part-id="0e7ef960-f7ad-4e5f-bf54-19a7d6021417"] {
        padding: 0;
      }
    }
  }

  .BBFormContainer {
    // sass-lint:disable-line class-name-format
    &[data-bbox-part-id="51e731f8-7be3-4530-a3f6-befb8bca69f1"] {
      .BBFormServiceUserNumberLabel {
        // sass-lint:disable-line class-name-format
        width: 160px;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .donate-tool {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .donate-tool {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .donate-tool {
    padding-top: 3.5rem;

    .small-page-title {
      letter-spacing: 0.88px;
      line-height: 2.2rem;
    }

    .form {
      .select-wrapper {
        flex-direction: column;

        .select {
          margin-left: 0;
          margin-right: 0;
        }

        .form-field {
          .message-wrapper {
            right: 0;
          }

          &[data-push-right] {
            .select {
              margin-left: 0;
              margin-right: 5rem;
            }

            .message-wrapper {
              right: 0;
            }
          }

          + .form-field {
            padding-left: 0;
            margin-top: 10px;
          }
        }
      }

      .btn-and-text-wrapper {
        display: block;

        .button-wrapper {
          margin-bottom: 1rem;
          max-width: none;
        }
      }
    }

    &-content {
      margin-bottom: 2.4rem;
    }

    &.vertical-accordion {
      .accordion {
        flex-direction: column;
        margin: 0 -2rem;

        > li {
          width: 100%;
          margin-bottom: 0;

          &:first-child {
            border-right-width: 0;
          }

          &:last-child {
            border-left-width: 0;
            border-top-width: 0.1rem;
            margin-top: 0.1rem;

            .accordion-drop {
              transform: none;
              left: 0;
            }
          }

          > .toggle-link {
            font-size: 2rem;
            line-height: 2.4rem;
            padding: 1.6rem;

            &[aria-expanded="true"] {
              border-bottom: 0.1rem solid variables.$projectBone;
            }
          }

          .accordion-drop {
            width: 100%;

            .content {
              margin-left: -2rem;
              margin-right: -2rem;
              padding: 2.4rem 2rem;
            }
          }
        }
      }
    }

    // Inner accordion
    .donate-tool-vertical-accordion {
      background-color: variables.$projectWhite;
      margin-top: 2.4rem;
      margin-bottom: 2.4rem;

      .donate-tool-accordion {
        > li {
          .donate-tool-accordion-drop {
            .content {
              padding: 2.4rem 0;
            }
          }
        }
      }
    }

    .journey-step {
      margin-bottom: 2.3rem;

      .step-count {
        span {
          font-size: 4rem;
        }

        &:first-child {
          margin-right: 2.5rem;

          &::after {
            border-width: 0.1rem;
            margin-top: -0.4rem;
            width: 5.2rem;
          }
        }

        &:last-child {
          margin-left: 2.5rem;
        }
      }
    }

    .cta-continue {
      width: 100%;
    }

    .donate-details {
      padding: 0;
      border-width: 0;
    }

    .donation-form {
      border-top: 0.1rem solid variables.$projectSantaGray;
      margin-left: -2rem;
      margin-right: -2rem;
    }

    .donation-button-tab-wrapper {
      flex-direction: column;

      .cta-donate {
        border-bottom: 0.1rem solid variables.$projectSantaGray;
        color: variables.$projectBlue;
        font-weight: 700;
        padding: 2.9rem 2rem 2.4rem;
        text-align: left;
        opacity: 1;
        font-size: 1.6rem;

        &.is--active {
          background-color: variables.$projectBlue;
          border-bottom: 0.1rem solid variables.$projectSantaGray;
          color: variables.$projectWhite;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .donate-tool {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .donate-tool {
  }
}

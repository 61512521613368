@use "sass:math";

@mixin hyperlink-initial-state($color, $big) {
  color: $color;
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;

  &::after {
    background-color: $color;
    height: 0.1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    display: block;
    visibility: hidden;
    width: 0;
    content: "";

    @if $big {
      height: 0.8rem;
    }
  }
}

@mixin hyperlink-state($color, $big) {
  @include hyperlink-initial-state($color, $big);

  &:hover,
  &:focus {
    outline: none;

    &::after {
      visibility: visible;
      width: 100%;
      max-width: 100%;
    }
  }
}

@mixin hyperlink-state-active($color) {
  &:hover,
  &:focus {
    outline: none;

    &::after {
      visibility: visible;
      width: 100%;
      max-width: 100%;
    }
  }
}

@mixin link-underline($color) {
  position: relative;
  width: fit-content;

  &::after {
    background-color: $color;
    height: 0.1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    display: block;
    visibility: visible;
    width: 100%;
    content: "";
  }

  &:hover {
    &::after {
      animation: out-in 0.5s;
    }
  }
}

@mixin carousel-pagination {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(10.5rem, -50%);
  background-color: $projectBlue;
  color: white;
  padding: 1.5rem;
  font-size: 2.4rem;
  font-family: $headingFontFamily;
  cursor: pointer;
  z-index: 2;

  strong {
    color: $projectRed;
  }

  svg {
    fill: white;
    transform: translateY(5px) translateX(3px);
  }

  &:hover,
  &:focus {
    background-color: white;
    color: $projectBlue;
    border: 1px solid $projectBlue;

    svg {
      fill: $projectBlue;
    }
  }

  @media #{$compact-web}, #{$tablet} {
    transform: translate(30%, -50%);

    span,
    strong {
      display: none;
    }

    svg {
      transform: none;
    }
  }
}

// CUSTOM ROW to match design padding
@mixin custom-base-padding {
  padding-left: 2rem;
  padding-right: 2rem;
}

// CUSTOM ROW to match design row
@mixin custom-row {
  margin-left: -2rem;
  margin-right: -2rem;
}

// UNDERLINE STYLE
@mixin underline {
  background-image: linear-gradient(90deg, #7f8eb0, #7f8eb0);
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0.1rem;
  padding-bottom: 0.4rem;
}

// UNDERLINE
@mixin underline($color: #7f8eb0) {
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0.1rem;
  padding-bottom: 0.4rem;

  &:focus,
  &:hover {
    background-image: linear-gradient(90deg, $color, $color);
  }
}

@mixin link-underline-animation() {
  animation: out-in 0.5s;

  @keyframes out-in {
    0% {
      width: 100%;
    }

    50% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }
}

@mixin line-height($lineHeight: 22, $fontSize: 16) {
  line-height: calc($lineHeight / $fontSize);
}

@mixin letter-spacing($letterSpacing) {
  letter-spacing: math.div($letterSpacing, 1000) * 1rem;
}

// CAROUSEL BUTTONS
@mixin carousel-buttons {
  text-align: center;
  width: 100%;
  margin-top: 2rem;

  button {
    position: relative;
    width: 1.2em;
    height: 1.2rem;
    padding: 1rem;
    background: none;
    border: 0;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 1px solid #001e62;
      background-color: transparent;
      width: 1.2em;
      height: 1.2rem;
      padding: 0;
    }

    &.tns-nav-active {
      &::after {
        background-color: #001e62;
      }
    }
  }
}

// POPUP FOR TOOLTIP
@mixin message-popup {
  position: absolute;
  bottom: 0.3rem;
  right: 0;
  min-height: 0;
  max-width: 25.7rem;

  .message {
    font-size: 1.3rem;
    margin: 0;
    padding-top: 0.2rem;
    clear: left;
    background: white;
    position: absolute;
    min-width: 10rem;
    bottom: 4rem;
    right: 1rem;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.41);
    padding: 0.5rem 1rem;
    text-align: right;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    z-index: 1;

    &::after {
      position: absolute;
      bottom: -2rem;
      right: 1rem;
      content: "";
      width: 3rem;
      height: 2rem;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAYAAABIB77kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpFRDY3NEE4MEI1RTIxMUU4OTFGNUE1OUMzNjhBOTQyMiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpFRDY3NEE4MUI1RTIxMUU4OTFGNUE1OUMzNjhBOTQyMiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkVENjc0QTdFQjVFMjExRTg5MUY1QTU5QzM2OEE5NDIyIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkVENjc0QTdGQjVFMjExRTg5MUY1QTU5QzM2OEE5NDIyIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+2OrsbAAAAfZJREFUeNqskz1PAkEQhhc4OfxAFEWNimKUjsLCisZY2ouhtLDwB6AFFa2x4z8QShL/gRbQGfsrDI2hMSRIDNzXOktmybAeJ4ibvMnd3u48887MMcZYABUEhSzLeuD/s9x+v38nYmJswRisIQw0l0qlIqZpVmeEWZ1O5xrihUEaxpbGhkAND8zncrk4QJ/+ZMt1v5rN5iXEWQRFhAnVZUC6A+l4MFYoFA6gJC/TwBzHaTcajXO4vwqKiuSJS09gBIEroESxWMyA07eJamhZ75VK5QzubYDioGXQwjhgUHE4AIK2y+XyqW3bH36wXq9nlEqlLJzfBW0i0Nch7aHIKgZaF0DQfrVavYByfXrBut3uaz6fP4FzKdAOOhQlXfLroZzSMGYVxSy3QEnQYa1Wu4KBMCms3W4/Z7PZY/EdtIfn10g5ddWd+mtodHBkWTH7dL1evxWDKGCtVusxmUxmYP9IVAHPJbAdvu4Y+UeoWw0vUGmGYdzouh5Pp9P3MMU27ElZRA7KRXHUiE0KDRENYUq2nASWQAl3cZ9TGMMgcnHy7LDRxTGIdE/3HAKizn7AVKAKdckeBQaVfQrxhXkBKdQd4zCgJMVJr3xhag+9vtFBou9MCewlNi2QKQD1mXuAmR9sEqDXJDOfvvPfgoXYbItPe+FbgAEA6+0FyYu93FAAAAAASUVORK5CYII=")
        no-repeat;
    }
  }

  &::before {
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%23001e62%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
    position: relative;
    content: "";
    width: 2rem;
    height: 2rem;
    display: inline-block;
    right: 0.6rem;
  }

  &::after {
    content: "";
    width: 14rem;
    height: 2rem;
    position: absolute;
    top: -2rem;
    left: -10rem;
    pointer-events: none;
  }

  &:not(.inactive) {
    &:focus,
    &:hover {
      outline: 0;

      .message {
        opacity: 1;
        pointer-events: unset;
      }

      &::before {
        background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23001e62%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
        transform: rotate(180deg);
      }

      &::after {
        pointer-events: unset;
      }
    }
  }

  &.light {
    .message {
      color: $projectBlue;
    }

    &::before {
      background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237988ac%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
    }

    &:focus,
    &:hover {
      &::before {
        background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
        transform: rotate(180deg);
      }
    }
  }
  // ie 11 fixes
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    &:focus,
    &:hover {
      .message {
        visibility: visible;
      }
    }

    .message {
      visibility: hidden;
      pointer-events: auto;

      &::after,
      &::before {
        pointer-events: auto;
      }
    }
  }

  svg {
    display: none;
  }

  @media #{$mobile}, #{$tablet} {
    .message {
      padding-right: 3rem;
    }

    a {
      position: absolute;
      right: -0.5rem;
      top: -0.5rem;
      display: block;
      padding: 1.2rem;

      svg {
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        fill: #001e62;
      }
    }
  }
}

@mixin section-row {
  display: flex;
  margin-bottom: 1.5rem;

  .label {
    min-width: 7.5rem;
    display: inline-block;
    font-size: 1.2rem;
    padding-top: 0.3rem;
  }

  .info {
    font-size: 1.6rem;

    .sub-text {
      display: block;
      font-size: 1.2rem;
      color: $projectInteractiveNavy;
    }
  }
}

@use "../../styles/variables";

.stories {
  margin: 0 -2rem;
  overflow-x: hidden;

  nav {
    position: fixed;
    left: 93vw;
    top: 47.5%;
    transform: translateY(-50%);
    z-index: 3;
    color: white;
    opacity: 0;
    transition: opacity 0.5s;

    .hidden {
      position: absolute;
      left: -10000px;
      top: auto;
      width: 1px;
      height: 1px;
      overflow: hidden;
    }

    .pages {
      font-size: 1.6rem;
      font-family: variables.$headingFontFamily;

      strong {
        height: 38.4px;
        width: 2.4px;
        display: inline-block;
        transform: rotate(20deg) translateY(1.3rem) translateX(0.5rem);
        background-color: #ffffff;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;

      li {
        position: relative;

        a {
          display: block;
          height: 0.9rem;
          width: 0.9rem;
          border-radius: 50%;
          background-color: transparent;
          border: 1px solid white;
        }

        &.active {
          a {
            background-color: white;
          }
        }

        &:hover,
        &:focus {
          .title {
            opacity: 1;
            visibility: visible;
          }
        }

        .title {
          background-color: variables.$projectBone;
          position: absolute;
          transform: translate(-100%, -50%) translateX(-2rem);
          color: variables.$projectBlue;
          top: 50%;
          white-space: nowrap;
          opacity: 0;
          visibility: hidden;
          padding: 1rem 2rem;
          font-weight: bold;

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #ebe8e5;
            bottom: 0;
            top: 50%;
            right: 0;
            transform: translate(98%, -50%);
          }
        }
      }
    }

    &.blue {
      color: variables.$projectBlue;

      .pages {
        strong {
          background-color: variables.$projectBlue;
        }
      }

      ul {
        li {
          a {
            border: 1px solid variables.$projectBlue;
          }

          &.active {
            a {
              background-color: variables.$projectBlue;
            }
          }

          .title {
            background-color: variables.$projectBlue;
            color: variables.$projectBone;

            &::after {
              border-left: 10px solid variables.$projectBlue;
            }
          }
        }
      }
    }
  }

  &.animating {
    nav {
      ul {
        li {
          a {
            cursor: wait;
          }
        }
      }
    }
  }

  .slide-down {
    position: absolute;
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 4rem;
    border: 0.1rem solid variables.$projectBlue;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    display: none;

    :global(.chevron) {
      transform: translateY(-50%) rotate(90deg);
      display: block;
      top: 1rem;
      position: relative;
      width: 100%;

      &::after {
        border-color: variables.$projectBlue;
        right: 0.5rem;
      }
    }

    &:hover {
      border: 0.3rem solid white;

      :global(.chevron) {
        &::after {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }

  section {
    width: 100vw;
    min-height: 95vh;
    padding: 0 2rem;

    .content {
      .read-more {
        font-size: 1.8rem;
        font-weight: bold;
        position: relative;
        padding-left: 2.5rem;
        margin: 3rem 0;
        cursor: pointer;
        display: block;
        text-decoration: none;

        &:hover,
        &:focus {
          opacity: 0.8;
        }

        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 1.4rem;
          height: 0.2rem;
          background-color: variables.$projectBlue;
          left: 0;
          top: 50%;
          transition: transform 0.5s;
        }

        &::before {
          transform: translateY(-50%) translateY(-0.1rem);
        }

        &::after {
          transform: rotate(90deg) translateX(-0.2rem);
        }
      }
    }

    ul {
      margin: 2rem 0 2rem 1.8rem;

      li {
        margin-bottom: 2rem;
        position: relative;
        padding-left: 1rem;

        &::before {
          content: "";
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          border: 2px solid variables.$projectRed;
          border-radius: 50%;
          left: -1.4rem;
          top: 0.5rem;
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;

          p {
            margin: 0 0 0 1rem;
          }
        }
      }
    }

    &.quote,
    &.small-quote,
    &.end {
      position: relative;

      .content {
        width: 100%;
        min-height: 95%;
        padding: 8rem 0 10rem;
        z-index: 2;

        h3 {
          &.title {
            &.short {
              margin-bottom: 12rem;
            }
          }
        }
      }

      .social-sharing {
        flex-direction: row-reverse;

        > a {
          color: white;
          margin-right: 2rem;
        }
      }
    }

    &.end {
      background-color: variables.$projectBlue;
      color: white;
      height: auto;

      .content {
        position: static;

        :global(.large) {
          font-size: 3rem;
          line-height: 4.2rem;
          max-width: 75rem;

          a {
            color: variables.$projectWhite;
            text-decoration: none;

            &:focus,
            &:hover {
              background-image: linear-gradient(
                90deg,
                variables.$projectInteractiveNavy,
                variables.$projectInteractiveNavy
              );
              background-repeat: no-repeat;
              background-position: 0 100%;
              background-size: 100% 0.1rem;
              padding-bottom: 0.4rem;
            }
          }
        }

        .bottom {
          margin-top: 6rem;
          display: flex;

          > div {
            &:first-child {
              position: relative;
              display: flex;
              align-items: center;
            }

            &:last-child {
              margin-left: auto;
            }
          }
        }

        .cta {
          width: auto;
          margin-right: 4rem;
        }

        h3 {
          &.title {
            margin-bottom: 10rem;
          }
        }

        .message-wrapper {
          @include variables.message-popup;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);

          .message {
            right: -1.1rem;

            &::after {
              bottom: -1.9rem;
            }
          }
        }
      }
    }

    &.quote {
      position: relative;

      &.blue {
        background-color: variables.$projectBlue;
        color: white;

        .slide-down {
          border: 0.1rem solid white;

          :global(.chevron) {
            &::after {
              border-color: white;
            }
          }
        }
      }

      &.with-image {
        color: white;

        .slide-down {
          border: 0.1rem solid white;

          :global(.chevron) {
            &::after {
              border-color: white;
            }
          }

          &:hover {
            border: 0.3rem solid white;

            :global(.chevron) {
              &::after {
                width: 0.9rem;
                height: 0.9rem;
                // border-top: 0.3rem solid white;;
                // border-right: 0.3rem solid white;;
              }
            }
          }
        }
      }

      &.landing {
        .content {
          h1 {
            max-width: 90rem;
          }
        }
      }

      .content {
        position: relative;
        max-width: 1140px;
        width: calc(100% - 6rem);

        h1 {
          margin-bottom: 4.2rem;
          max-width: 95rem;
        }
      }

      .picture {
        picture {
          position: absolute;
          overflow: hidden;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: "object-fit: cover;";
        }
      }
    }

    &.small-quote {
      &.blue {
        background-color: variables.$projectBlue;
        color: white;

        .read-more {
          color: white;

          &::after,
          &::before {
            background-color: white;
          }
        }

        .slide-down {
          border: 0.1rem solid white;

          :global(.chevron) {
            &::after {
              border-color: white;
            }
          }
        }
      }

      .content {
        max-width: 50%;

        &.slim {
          max-width: 41.66667%;
        }
      }

      .picture {
        position: absolute;
        right: 0;
        top: 50%;
        max-width: 41.67%;
        transform: translateY(-50%);
        max-height: 60rem;
      }

      &.img-flush {
        picture {
          height: 100%;
        }
        .picture {
          max-width: 44.445%;
          height: 100%;
          max-height: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .line {
      position: absolute;
      width: 0.5rem;
      background-color: variables.$projectRed;
      height: 0;
      z-index: 1;
      transition: height 0.5s;

      &.initial {
        visibility: hidden;
        transform: translateY(-14.5rem);
      }

      &.mobile {
        display: none;
      }
    }

    .read-more-offscreen {
      width: 44.445%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: variables.$projectDarkBlue;
      padding: 10rem 5rem 5rem;
      height: 100%;
      z-index: 4;
      transform: translateX(100%);
      transition: transform 0.5s, visibility 0.5s;
      transition-delay: visibility 0.5s;
      color: white;
      visibility: hidden;

      .read-more-inner {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
        padding-right: 5rem;
        position: relative;

        a {
          color: white;
        }
      }

      .close {
        width: 2rem;
        margin-left: 4rem;
        cursor: pointer;
        font-size: 0;
        height: 1.6rem;
        right: 5rem;
        top: 3rem;
        position: absolute;

        &:active,
        &:focus,
        &:hover {
          svg {
            fill: variables.$projectInteractiveNavy;
          }
        }

        svg {
          fill: #fff;
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }

    &.landing {
      .social-sharing {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 0;

        ul {
          margin: 0;
        }
      }

      .slide-down {
        display: block;
      }
    }

    &.open {
      .read-more-offscreen {
        transform: translateX(0);
        visibility: visible;
      }

      .read-more {
        &::after {
          transform: translateY(-50%) translateY(-0.1rem);
        }
      }
    }
  }

  &.initialized {
    .landing {
      .line {
        &.initial {
          height: 13.4rem;
        }
      }
    }

    nav {
      opacity: 1;
    }
  }

  .promotional-articles {
    padding: 12rem 2rem 8rem;
  }

  &.editing {
    section {
      height: auto;

      &.quote {
        .content {
          position: relative;
        }
      }
    }
  }

  :global(.play-on-image) {
    &:active,
    &:focus,
    &:hover {
      img {
        opacity: 1;
      }

      :global(.play-icon) {
        background-color: rgba(variables.$projectBlue, 0.5);
      }
    }

    :global(.play-icon) {
      position: absolute;
      height: 14rem;
      width: 14rem;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-radius: 50%;
    }
  }

  :global(.play) {
    &:active,
    &:focus,
    &:hover {
      :global(.play-icon) {
        background-color: rgba(variables.$projectBlue, 0.5);
      }
    }

    :global(.play-icon) {
      height: 9.6rem;
      width: 9.6rem;
      border-radius: 50%;
      float: left;
      margin-top: -1.5rem;
      margin-right: 2.4rem;
    }
  }

  .visually-hidden {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: 0.1rem;
    height: 0.1rem;
    overflow: hidden;
  }

  .social-sharing {
    ul {
      li {
        &::before {
          content: none;
        }
      }
    }
  }

  :global(.subnav-links) {
    margin-left: 30px;
  }
}

// sass-lint:disable class-name-format
.basicLightbox {
  &__placeholder {
    &.fullsize {
      width: 100%;
      height: 100%;
    }
  }
  // sass-lint:enable class-name-format

  .video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$projectWhite;
    height: 100%;
    width: 100%;
  }

  .visually-hidden {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: 0.1rem;
    height: 0.1rem;
    overflow: hidden;
  }

  .close-lightbox {
    position: absolute;
    width: 3rem;
    height: 3rem;
    right: 2.1rem;
    top: 2.1rem;

    svg {
      fill: variables.$projectBlue;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .stories {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .stories {
    :global(.subnav-links) {
      margin-left: 3.2rem;

      li {
        &:first-child {
          &:after {
            left: 0;
          }
        }
      }
    }

    nav {
      left: 92vw;

      ul {
        li {
          .title {
            display: none;
          }
        }
      }
    }

    section {
      min-height: 0;

      &.quote,
      &.small-quote,
      &.end {
        .content {
          h3 {
            &.title {
              margin-bottom: 8rem;

              &.short {
                margin-bottom: 8rem;
              }
            }
          }
        }
      }

      &.quote {
        .content {
          max-width: calc(100% - 6rem);
        }
      }

      &.small-quote {
        .content {
          &.slim {
            max-width: 50%;
          }
        }
      }

      .line {
        &.initial {
          transform: translateY(-7.5rem);
        }
      }
    }

    &.initialized {
      .landing {
        .line {
          &.initial {
            height: 7.4rem;
          }
        }
      }
    }
  }
}

@media #{variables.$tablet-portrait} {
  .stories {
    nav {
      left: 90vw;
    }

    .line {
      &.initial {
        visibility: visible;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-height: 800px) {
  .stories {
    .h1,
    h1 {
      font-size: 5.6rem;
    }

    .h3,
    h3 {
      font-size: 3.8rem;
    }

    section {
      &.quote {
        .content {
          h3 {
            &.title {
              margin-bottom: 8rem;
            }
          }
        }
      }

      &.small-quote {
        .content {
          h3 {
            &.title {
              margin-bottom: 8rem;

              &.short {
                margin-bottom: 8rem;
              }
            }
          }
        }
      }

      .line {
        &.initial {
          transform: translateY(-7.5rem);
        }
      }
    }

    &.initialized {
      .landing {
        .line {
          &.initial {
            height: 7.4rem;
          }
        }
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile}, #{variables.$tablet-portrait} {
  .stories {
    nav {
      display: none;
    }

    .slide-down {
      bottom: 2rem;
      display: none;
    }

    section {
      height: 100%;
      padding: 3rem 2rem 6rem;

      .line {
        &:not(.initial) {
          display: none;
        }

        &.initial {
          visibility: visible;
        }

        &.mobile {
          display: block;
        }
      }

      .content {
        .read-more {
          margin-bottom: 0;
        }
      }

      &.quote,
      &.small-quote,
      &.end {
        .content {
          padding: 0;

          h3 {
            &.title {
              margin-bottom: 4rem;

              &.short {
                margin-bottom: 4rem;
              }
            }
          }
        }
      }

      &.quote {
        .content {
          position: static;
          padding: 0;
        }

        .picture {
          picture {
            position: static;
            width: calc(100% + 4rem);
            margin: 0 -2rem;
            height: auto;

            img {
              height: auto;
            }
          }
        }

        &.with-image {
          background-color: variables.$projectBlue;
          padding: 0 2rem 8rem;

          > :global(.wrapper) {
            display: flex;
            flex-direction: column-reverse;
          }

          .content {
            width: 100%;
            padding: 5rem 0 0;

            h3 {
              &.title {
                position: absolute;
                top: 3rem;
              }
            }

            h1 {
              font-size: 4rem;
            }
          }
        }
      }

      &.small-quote {
        padding: 10rem 2rem 6rem;

        > :global(.wrapper) {
          display: flex;
          flex-direction: column-reverse;
          // max-height: calc(100vh - 13rem);
          // overflow: scroll;
        }

        .content {
          margin: 3rem 0;
          max-width: 100%;
          overflow: auto;

          &.slim {
            max-width: 100%;
          }

          h3 {
            &.title {
              position: absolute;
              top: 3rem;
            }
          }
        }

        .picture {
          position: static;
          transform: translateX(2rem);
          max-width: 100%;
          max-height: 100%;
          margin-left: 5rem;
        }

        &.img-flush {
          .picture {
            position: static;
            transform: translateX(2rem);
            max-width: 100%;
            max-height: 100%;
            height: 0;
            padding-bottom: calc(100% - 5rem);

            img {
              position: absolute;
            }
          }
        }
      }

      &.landing {
        .social-sharing {
          display: none;
        }

        .line {
          &.initial {
            transform: translateY(-57vw);
          }
        }

        .slide-down {
          display: none;
        }
      }

      &.end {
        .content {
          h3 {
            &.title {
              margin-bottom: 6rem;
            }
          }

          :global(.large) {
            font-size: 2rem;
            line-height: 3rem;
          }

          .bottom {
            display: block;

            > div {
              &:first-child {
                flex-direction: column;
              }
            }

            .cta {
              margin-right: 0;
              margin-bottom: 3rem;

              &:not(.tertiary) {
                width: 100%;
              }
            }

            .message-wrapper {
              bottom: 2rem;
              top: auto;
            }
          }
        }

        .social-sharing {
          display: none;
        }
      }

      .read-more-offscreen {
        position: fixed;
        width: 100%;
        padding: 10rem 2rem 2rem;

        .read-more-inner {
          padding-right: 0;
        }

        .close {
          right: 2rem;
        }
      }
    }

    &.initialized {
      .landing {
        .line {
          &.initial {
            height: 54vw;
          }
        }
      }
    }

    .promotional-articles {
      padding: 6rem 2rem 8rem;
    }

    :global(.play-on-image) {
      :global(.play-icon) {
        z-index: 1;
      }
    }
  }
}

html {
  &.story-opened {
    overflow-y: hidden;
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .stories {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .stories {
  }
}

@use "../../styles/variables";
@use "../C241_UpcomingProgrammes/C241_UpcomingProgrammes.module";

// COMPONENT - C250 Related courses

// DEFAULT CLASS - .related-courses

// CLASS VARIANTS -

// WIDE WEB

.related-courses {
  @extend .upcoming-programmes;

  .picture {
    position: absolute;
    left: -1rem;
    width: 42%;
    height: auto;

    + div {
      margin-left: 50%;

      + div {
        margin-left: 50%;

        + div {
          margin-left: 50%;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 16rem;
      height: 0.5rem;
      background-color: #c8103e;
      z-index: 2;
      transform: translateX(65%) translateY(11rem);
      transition: width 1s;
      transition-delay: 0.5s;
      right: 0;
      top: 0;
    }
  }

  .programmes {
    p {
      &.tag {
        font-size: 1.4rem;
      }
    }

    .programme {
      flex: 1;

      .bottom {
        margin-top: auto;
        p {
          padding-top: 2rem;
        }
      }
    }

    &::after {
      content: none;
    }
  }

  &.image {
    padding: 0 2rem 8rem;
    position: relative;
    overflow: hidden;

    .programmes {
      margin-top: 11.6rem;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .related-courses {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .related-courses {
  }
}

// TABLET
@media #{variables.$tablet} {
  .related-courses {
    .programmes {
      .container {
        display: block !important;
        padding-right: 0 !important;
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .related-courses {
    .picture {
      position: static;
      margin: -1rem;
      width: calc(100% + 2rem);
      margin-bottom: 2rem;

      + div {
        margin-left: 0;

        + div {
          margin-left: 0;

          + div {
            margin-left: 0;
          }
        }
      }

      &::after {
        width: 0.5rem;
        height: 12rem;
        top: unset;
        right: unset;
        transform: translateX(22px) translateY(-70%);
      }
    }

    p {
      &.label {
        margin-bottom: 1rem;
      }
    }

    h2 {
      margin-bottom: 0;
    }

    .programmes {
      margin-top: 2rem;
      .container {
        display: block;
      }
    }

    &.image {
      .programmes {
        margin-top: 2rem;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .related-courses {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .related-courses {
  }
}

@use "../../styles/variables.scss";
// COMPONENT - Registration form

.reg-form {
  padding: 0 2rem 3.4rem 2rem;
  margin-bottom: 3.9rem;
  margin-top: 3.4rem;
  border-bottom: 0.1rem solid variables.$projectLightGrey;
}

.back {
  max-width: 300px;
}

// CLASS VARIANTS -

// WIDE WEB
.component {
  margin-top: 4rem;
}
.edit-details {
  .error {
    ul {
      margin-bottom: 3rem;
      li {
        display: flex;

        &::before {
          flex-shrink: 0;
        }
      }
    }
  }
}

.fields-floated {
  a {
    text-decoration: underline;
  }

  .field-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.6rem;

    label {
      flex-basis: 25%;
      max-width: 25%;
      color: variables.$projectBlue;
      font-family: variables.$baseFontFamily;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 700;
      padding-right: 1rem;

      + p {
        flex-basis: 75%;
        max-width: 75%;
        margin-bottom: 0;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
}

// MOBILE
@media #{variables.$mobile} {
  .fields-floated {
    .field-row {
      flex-direction: column;

      label {
        flex-basis: 100%;
        max-width: 100%;

        + p {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &.edit-details {
        a {
          width: 100%;
        }
      }
    }
  }

  // MOBILE LANDSCAPE
  @media #{variables.$mobile-landscape} {
  }

  // MOBILE PORTRAIT
  @media #{variables.$mobile-portrait} {
  }
}

@use "../../styles/variables";

// COMPONENT - C03 Sub navigation
// DEFAULT CLASS - .sub-navigation
// CLASS VARIANTS -
// WIDE WEB

.contact {
  background: variables.$projectBlue;
  color: white;
  margin: 0 -2rem;
  padding: 0 2rem;

  // sass-lint:disable class-name-format
  .base12_tp6 {
    + .base12_tp6 {
      margin-top: 3rem;
    }
  }

  .base6_tp12 {
    > div {
      > div:last-child {
        margin-top: 3rem;
      }
    }
  }

  .header-section {
    padding: 6rem 0;
    border-bottom: 1px solid #405689;
    margin-bottom: 5rem;

    h4 {
      font-size: 6rem;
    }

    p {
      font-size: 2rem;
    }
  }

  .content {
    padding-bottom: 9rem;

    .contact-person {
      margin-bottom: 3.6rem;
      display: flex;

      > a {
        display: flex;
        text-decoration: none;
        font-weight: 400;
        color: white;

        &:hover {
          .contact-info {
            .name {
              span {
                background-image: linear-gradient(90deg, #7f8eb0, #7f8eb0);
                background-repeat: no-repeat;
                background-position: 0 100%;
                background-size: 100% 0.1rem;
                padding-bottom: 0.3rem;
              }
            }
          }
        }
      }

      .image-wrapper {
        min-width: 9.8rem;
        margin-right: 3rem;

        img {
          width: 100%;
        }
      }

      .contact-info {
        margin-top: -0.3rem;

        .name {
          font-size: 3rem;
          // display: flex;
          position: relative;
          display: inline;

          i {
            background-image: none;
            min-width: 2.3rem;
            min-height: 2.3rem;
            padding-top: 0.2rem;
            margin-left: 1.5rem;

            svg {
              width: 2.3rem;
              height: 2.3rem;
            }
          }
        }

        .position {
          letter-spacing: 0.5px;
          color: #9b9baf;
          text-transform: uppercase;
          font-size: 1.4rem;
          position: relative;
          display: table;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .contact-details {
      a {
        color: white;
        text-decoration: none;
      }

      .icon {
        display: grid;
        width: 2rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        svg {
          fill: white;
        }
      }

      h5 {
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #9b9baf;
        font-family: variables.$baseFontFamily;
        margin-bottom: 3rem;
      }

      .contact-link {
        font-weight: normal;
        font-size: 1.6rem;
        position: relative;

        a {
          font-weight: 400;
        }

        :global(.tertiary) {
          margin-left: 0;
        }
      }

      .option-links {
        margin-top: 3rem;

        a {
          color: white;
          text-decoration: none;
          position: relative;
        }
      }
    }
  }

  &.blue {
    background: variables.$projectBlue;
    color: white;
  }
}

.contact-details-extended {
  .base6_tp12 {
    > div {
      > div:last-child {
        margin-top: 0;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$tablet} {
  .contact {
    .header-section {
      text-align: center;
    }

    .content {
      .base6_tp12 {
        &:first-child {
          &:not([data-push-left]) {
            position: relative;
            margin-bottom: 4.5rem;
            padding-bottom: 4.5rem;

            &::before {
              content: "";
              position: absolute;
              width: calc(100% - 2rem);
              height: 1px;
              background-color: #405689;
              bottom: 0;
              left: 1rem;
            }
          }
        }
      }
    }
  }
}

.contact-details-extended {
  .content {
    .base6_tp12 {
      &:first-child {
        &:not([data-push-left]) {
          position: relative;
          margin-bottom: 0;
          padding-bottom: 1rem;

          &::before {
            content: none;
          }
        }
      }
    }
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .contact {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .contact {
    .header-section {
      padding: 4rem 0 3rem;
      margin-bottom: 0;

      h4 {
        font-size: 4rem;
        margin-bottom: 2rem;
        text-align: left;
      }

      p {
        margin: 0;
        font-size: 1.6rem;
        text-align: left;
      }
    }

    .content {
      padding: 0 0 6rem;

      .base6_tp12 {
        &:first-child {
          &:not([data-push-left]) {
            margin-bottom: 0;
            padding-bottom: 3rem;
            margin-top: 3rem;
          }
        }
      }

      .contact-person {
        margin-bottom: 2rem;

        a {
          .image-wrapper {
            margin-right: 2rem;
          }

          .contact-info {
            position: relative;
            padding-right: 2rem;

            .name {
              font-size: 2rem;
              margin-bottom: 0.2rem;
              position: static;
              display: flex;
            }
          }
        }

        .image-wrapper {
          min-width: 5.5rem;
        }
      }

      .contact-details {
        margin-top: 0;

        > div {
          > div {
            margin-top: 5rem;
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .contact {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .contact {
  }
}

// COMPONENT - C261 Contact details extended

// DEFAULT CLASS - .contact-details-extended

// CLASS VARIANTS -

// WIDE WEB

.contact-details-extended {
  .header-section {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .content {
    padding-bottom: 2rem;

    .contact-list {
      li.contact-container {
        border-top: 1px solid rgba(255, 255, 255, 0.25);
        margin-bottom: 0;
        padding: 5.5rem 0;

        &:last-child {
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        }

        .contact-person {
          margin-bottom: 0;
        }

        .contact-ctas {
          p {
            a {
              color: variables.$projectWhite;
            }
          }
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .contact-details-extended {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .contact-details-extended {
    .contact-list {
      li.contact-container {
        .contact-person {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .content {
    position: relative;
  }

  .contact.contact-details-extended {
    .content {
      .contact-details {
        margin-top: 0;

        > div {
          > div {
            margin-top: 0;
          }
        }
      }
    }

    .contact-list {
      align-items: stretch;
      display: flex;

      li.contact-container {
        margin: 0 -2rem;
        background: variables.$projectWhite;
        padding: 2.4rem;

        p,
        a,
        span {
          color: variables.$projectBlue;
        }

        .contact-link {
          a {
            color: variables.$projectBlue;
          }
        }

        .icon {
          svg {
            fill: variables.$projectBlue;
          }
        }

        .row {
          margin: 0;
          padding: 2.4rem;
        }

        .base6,
        .base3 {
          padding: 0;
        }

        .contact-person {
          border-bottom: 1px solid variables.$projectInteractiveNavy;
          margin-bottom: 2.4rem;
          padding-bottom: 2.4rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .contact-details-extended {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .contact-details-extended {
  }
}

.tns-nav {
  bottom: 0;
  left: 0;
  margin: 2rem 0;
  position: absolute;

  span {
    position: relative;
    width: 1.2rem;
    height: 1.2rem;
    padding: 1rem;
    background: none;
    border: 0;
    cursor: pointer;
    pointer-events: all;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 1px solid #fff;
      background-color: transparent;
      width: 1.2rem;
      height: 1.2rem;
      padding: 0;
      border-color: #fff;
      z-index: 2;
    }
  }
}

:global(.slick-active) {
  .tns-nav {
    span {
      &::after {
        background: variables.$projectWhite;
      }
    }
  }
}

@use "../../styles/variables.scss";

.article-header {
  padding: 3.9rem 0 6.2rem;

  h1 {
    @include variables.line-height(68, 60);
    font-size: 6rem;
    margin-bottom: 3.2rem;
  }

  p {
    @include variables.line-height(30, 20);
    font-size: 2rem;
    color: variables.$projectBlue;
  }

  .article-details-wrapper {
    border-top: 0.1rem solid variables.$projectLightGrey;
    overflow: hidden;
    margin-top: 2.3rem;

    .share {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    :global(#SaveToProfileLink) {
      color: variables.$projectBlue;
      font-size: 1.6rem;
      text-decoration: none;
      margin-left: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  .article-details {
    @include variables.line-height(22, 14);
    @include variables.letter-spacing(88);
    font-size: 1.4rem;
    color: variables.$projectGrey;
    text-transform: uppercase;
    padding: 2.2rem 0 1.9rem;
    margin: 0 0 2.5rem;
    display: flex;
    justify-content: space-between;
    position: relative;

    &::after {
      content: "";
      width: 200%;
      height: 0;
      display: block;
      border-bottom: 0.1rem solid variables.$projectLightGrey;
      left: 0;
      bottom: 0;
      position: absolute;
    }

    .author {
      padding-right: 4rem;

      a {
        font-weight: normal;
        text-decoration: none;
        color: variables.$projectGrey;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .date-read-time {
      display: flex;
      justify-content: space-between;

      span {
        margin-right: 3.1rem;

        &:last-child {
          margin-right: 0;
        }

        &.date {
          white-space: nowrap;
        }

        &.read-time {
          margin-left: 2.5rem;
          white-space: nowrap;
          position: relative;

          &::before {
            position: absolute;
            content: "|";
            left: -3rem;
          }
        }
      }
    }
  }

  .base4 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 2rem;

    .save-to-my-profile {
      margin: 0.2rem 0 0;
    }

    .social-sharing {
      margin-left: 2rem;
      padding-top: 0;

      .social-links {
        li {
          margin-right: 0.4rem;

          a {
            width: 2.8rem;
            height: 2.8rem;

            svg,
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .tag {
    span {
      font-size: 1.2rem;
    }

    a {
      font-size: 1.2rem;
    }
  }
}

.article-images {
  margin-bottom: 3rem;
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .article-header {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .article-header {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .article-header {
    padding: 3.1rem 0 5.4rem;

    h1 {
      @include variables.line-height(48, 40);
      font-size: 4rem;
      margin-bottom: 1.4rem;
    }

    .article-details-wrapper {
      margin-top: 1.1rem;

      .share {
        justify-content: flex-start;
      }

      :global(#SaveToProfileLink) {
        color: variables.$projectBlue;
        font-size: 1.6rem;
        text-decoration: none;
        margin-left: 1.5rem;
        margin-top: 2.5rem;
      }
    }

    .article-details {
      flex-direction: column;
      padding: 1.4rem 0 0.4rem;
      margin: 0 0 1.5rem;

      .author {
        margin-bottom: 0.8rem;
      }

      .date-read-time {
        flex-direction: column;

        span {
          display: block;
          margin-bottom: 0.8rem;

          &.read-time {
            margin-left: 0;

            &::before {
              content: none;
            }
          }
        }
      }
    }

    .base4 {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1.2rem;
      padding-top: 0;

      .social-sharing {
        margin-left: 0;
        padding-top: 2.3rem;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .article-header {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .article-header {
  }
}

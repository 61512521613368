@use "../../styles/variables.scss";

// COMPONENT - C09 Video Vimeo and Youtube
// DEFAULT CLASS - .youtube
// CLASS VARIANTS -
// WIDE WEB

.youtube {
  .overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .youtube-embed {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    picture {
      position: absolute;
      z-index: 1;
      width: 100%;
      cursor: pointer;
      opacity: 1;

      img {
        width: 100%;
      }
    }

    .play-icon {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      pointer-events: none;
      text-align: center;

      .img-wrapper,
      img {
        display: inline-block;
        width: 12.81%;
        min-width: 8.8rem;
        max-width: 14.6rem;
      }
    }

    &:hover {
      .play-icon {
        opacity: 0.9;
      }
    }
  }

  &.played {
    picture {
      visibility: hidden;
    }

    .play-icon {
      display: none;
    }
  }

  &.two-col {
    .play-icon {
      .img-wrapper,
      img {
        min-width: 8.8rem;
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .youtube {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .youtube {
  }
}
// MOBILE
@media #{variables.$mobile} {
  .youtube {
    .youtube-embed {
      margin-bottom: 2rem;

      .play-icon {
        img {
          max-width: none;
          width: 5.3rem;
          min-width: 0;
        }
      }
    }
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .youtube {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .youtube {
  }
}

@import "../../styles/variables.scss";

.header-banner-image {
  &.has-label {
    &.type-one {
      .text-with-line {
        h1 {
          margin-right: 6rem;
        }

        .content-wrapper {
          margin-top: 1.2rem;
        }
      }
    }
  }

  &.blue-main-page {
    margin: 0 -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background: $projectBlue;
    color: white;

    a {
      color: white;
    }

    .text-with-line {
      svg {
        fill: white;

        g {
          stroke: white;
        }
      }

      .section-label {
        color: $projectSantaGray;
      }
    }
  }

  &.blue {
    margin: 0 -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    background: $projectDarkBlue;
    color: white;

    a {
      color: white;
    }

    .text-with-line {
      svg {
        fill: white;

        g {
          stroke: white;
        }
      }

      .section-label {
        color: $projectSantaGray;
      }
    }
  }

  .main-content {
    position: relative;

    .text-with-line {
      .content-wrapper {
        display: inline-flex;

        .with-play {
          margin-top: 2rem;
          display: inline-flex;
          position: relative;
          font-weight: 700;
          font-size: 1.8rem;
          text-decoration: none;

          > span {
            display: flex;
            align-items: center;
            fill: white;
            margin-right: 1rem;
          }

          svg {
            width: 1.7rem;
            height: 1.7rem;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .text-with-line {
    position: absolute;
    left: 0;
    right: 0;
    top: 4rem;
    bottom: 12rem;
    padding: 4rem 0;
    min-height: auto;
    width: 100%;
    z-index: 1;

    .section-label {
      text-transform: uppercase;
      font-size: 1.4rem;
      font-family: $baseFontFamily;
      margin-bottom: 1.2rem;
      display: block;
      color: $projectGrey;
    }

    .small-page-title {
      margin: 0;
      font-size: 2rem;
      text-transform: uppercase;
      font-family: $baseFontFamily;
      margin-bottom: 2.5rem;
      margin-top: 4rem;
    }

    h2 {
      font-size: 7.6rem;
    }

    p {
      margin-bottom: 0;
    }

    .content-wrapper {
      .content {
        max-width: 55.5rem;

        p {
          font-size: 2rem;
        }

        :global(.cta) {
          margin-top: 4rem;
          margin-bottom: 2rem;
          width: auto;
        }

        :global(.light) {
          color: $projectBlue;
          &:hover {
            color: $projectWhite;
          }
        }

        :global(.red) {
          background-color: $projectRed;
          color: $projectWhite;
          border: 2px solid $projectRed;
          &:focus,
          &:hover {
            background-color: $projectLightGrey;
            border: 2px solid $projectBlue;
            color: $projectBlue;
          }
        }
      }
    }

    .line {
      position: absolute;
      top: 5rem;
      right: 0;
      width: 100%;
      height: calc(100% - 10rem);
      max-width: 104.5rem;
      pointer-events: none;

      &::after,
      &::before {
        width: 0;
        position: absolute;
        height: 0.5rem;
        background: $projectRed;
        content: "";
        top: 0;
        left: 0;
        transition: all 0.5s ease-in-out;
        transition-delay: 0.5s;
      }

      &::after {
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        transition-delay: 1.5s;
      }

      .inner-line {
        width: 0.5rem;
        background: $projectRed;
        height: 0;
        display: inline-block;
        float: right;
        transition: all 0.5s ease-in-out;
        transition-delay: 1s;

        &::after,
        &::before {
          width: 0.5rem;
          height: 0;
          background: $projectRed;
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          transition: all 0.5s ease-in-out;
          display: block;
          transition-delay: 2s;
        }

        &::after {
          bottom: calc(100% - 4.5rem);
          transition-delay: initial;
        }
      }
    }
  }

  .slide-down {
    position: absolute;
    bottom: 2rem;
    width: 5rem;
    height: 5rem;
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    border: 0.1rem solid white;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    color: $projectWhite;
    z-index: 2;

    .chevron {
      transform: translateY(-50%) rotate(90deg);
      display: block;
      top: 1rem;
      position: relative;
      width: 100%;

      &::after {
        border-color: white;
        right: 0.5rem;

        // css added from _uif_forms-elements.scss
        border-color: white;
        right: 0.5rem;
        position: absolute;
        content: "";
        width: 0.8rem;
        height: 0.8rem;
        top: 55%;
        transform: translateY(-50%) rotate(45deg);
        border-top: 0.2rem;
        border-right: 0.2rem;
        border-bottom: 0;
        border-left: 0;
        border-style: solid;
      }
    }

    &:hover {
      border: 0.3rem solid white;

      .chevron {
        &::after {
          width: 0.9rem;
          height: 0.9rem;
        }
      }
    }
  }

  .image-wrapper {
    text-align: right;
    font-size: 0;
    z-index: 1;

    .inner-wrapper {
      display: inline-flex;
      min-width: 50%;

      .picture {
        width: 100%;
        display: inline-block;
      }
    }
  }

  &.animate {
    .text-with-line {
      .line {
        .inner-line {
          height: 100%;

          &::after,
          &::before {
            height: 4.5rem;
          }
        }

        &::after,
        &::before {
          width: 100%;
        }
      }
    }

    &.no-image {
      .text-with-line {
        .content {
          &::before {
            height: 16.6rem;
          }
        }
      }
    }
  }

  &.type-one {
    .main-content {
      min-height: 45rem;
    }
    .text-with-line {
      top: 50%;
      transform: translateY(-50%);
      min-height: 47.4rem;
      bottom: auto;

      h1 {
        font-size: 6rem;
        margin-bottom: 1.8rem;

        &.short-title {
          font-size: 7.6rem;
        }
      }

      .content-wrapper {
        margin: 3rem 0;

        .content {
          max-width: 52rem;
        }

        .with-play {
          > span {
            fill: $projectBlue;
          }
        }

        &::after,
        &::before {
          left: 33.4rem;
        }

        p {
          margin-bottom: 4rem;
        }
      }

      .line {
        max-width: 74.7rem;
      }
    }

    .image-wrapper {
      span {
        width: 100% !important; //fix for nextImage
      }
      img {
        width: 100% !important; //fix for nextImage
        height: auto !important; //fix for nextImage
      }
    }
  }

  &.no-image {
    .text-with-line {
      position: static;
      transform: none;
      min-height: 0;

      h1 {
        margin-bottom: 0;
        font-size: 6rem;
      }

      p {
        margin-top: 2.5rem;
      }

      .content {
        position: relative;
        max-width: 63.8rem;

        :global(.cta) {
          margin-top: 4rem;
        }
      }

      .line {
        display: none;
      }
    }

    &.full-width {
      .text-with-line {
        .content {
          max-width: 100%;
        }
      }
    }
  }

  &.page-video {
    .text-with-line {
      z-index: 3;

      .line {
        max-width: 100rem;
        height: calc(100% + 5.5rem);
        top: -1rem;
      }
    }

    .image-wrapper {
      .inner-wrapper {
        display: inline-flex;
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 65%;

        .react-player,
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
        }

        .picture {
          position: relative;
          transition: opacity 0.5s;
        }

        .play-icon {
          position: absolute;
          z-index: 1;
          text-align: center;
          cursor: pointer;
          bottom: 2rem;
          right: 3rem;

          img {
            display: inline;
            max-width: 9rem;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  &.played {
    &.page-video {
      .image-wrapper {
        .inner-wrapper {
          .play-icon,
          .picture {
            opacity: 0;
            // pointer-events: none;
          }
        }
      }
    }
  }

  &.type-two {
    .main-content {
      display: flex;
    }

    .text-with-line {
      position: static;
      transform: none;
      padding: 2rem 0 0;
    }

    .image-wrapper {
      margin: 0;
      padding-left: 10rem;

      .inner-wrapper {
        position: relative;
      }
    }
  }

  &.type-three {
    .main-content {
      display: flex;
    }

    .content {
      margin: 5rem 0;
    }

    .text-with-line {
      position: static;
      transform: none;
      max-width: 55.5rem;
      padding: 0;
      margin: 0;
      padding-right: 11rem;

      h1 {
        font-size: 4.8rem;
        margin-bottom: 4.3rem;
      }
    }

    .image-wrapper {
      .inner-wrapper {
        margin-top: 4.5rem;

        img {
          height: 100%;
        }
      }
    }
  }

  :global(.rich-text) {
    a {
      border: none;
      text-decoration: underline;
      font-weight: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

body {
  &.lightbox-opened {
    position: fixed;
  }
}

// COMPACT WEB
@media all and (max-width: 1200px) {
  .header-banner-image {
    .slide-down {
      display: none;
    }

    &.page-video {
      .main-content {
        position: static;
        flex-direction: column-reverse;
        display: flex;
      }

      .text-with-line {
        position: static;
        margin: 0;
        padding: 0;

        .line {
          display: none;
        }

        .content-wrapper {
          .content {
            max-width: none;
            padding-right: 9rem;
          }
        }
      }

      .image-wrapper {
        position: relative;
        overflow: visible;
        width: 100%;
        .inner-wrapper {
          max-width: none;

          .play-icon {
            right: 3rem;
          }
        }
      }
    }

    &.type-one {
      padding: 0 3rem;

      .text-with-line {
        min-height: 36.5rem;
        padding: 6rem 0;

        .content-wrapper {
          width: 52%;
          margin: 0;

          p {
            font-size: 1.6rem;
            margin-bottom: 0.5rem;
          }

          .content {
            :global(.cta) {
              margin: 2rem 0;
            }
          }
        }

        h1 {
          font-size: 4rem;
        }

        .line {
          width: 53%;
          height: calc(100% - 8.65rem);
          top: 4.35rem;

          .inner-line {
            &::before {
              // display: none;
            }
          }
        }
      }

      .image-wrapper {
        min-height: 0;
        padding: 0 0 3rem;
      }

      .inner-wrapper {
        width: 50%;
        padding-left: 1%;
      }
    }
  }
}

// MOBILE
@media #{$mobile} {
  // sass-lint:disable force-element-nesting
  .header-banner-image,
  .header-banner-image.type-one {
    margin-bottom: 4rem;

    &.hide-line .image-wrapper::before {
      display: none !important;
      width: 10px;
    }

    .main-content {
      display: flex;
      flex-direction: column-reverse;
    }

    .text-with-line {
      max-height: none;
      position: static;
      transform: none;
      padding: 4rem 0;
      margin: 0;
      height: auto;
      min-height: 0;

      h1 {
        font-size: 3rem;

        &.short-title {
          font-size: 3rem;
        }
      }

      h2 {
        font-size: 5rem;
        margin-bottom: 0;
      }

      .small-page-title {
        margin-top: 0;
      }

      .content-wrapper {
        margin: 0;
        width: 100%;

        .content {
          padding-right: 2.5rem;

          p {
            margin-right: 4rem;
            font-size: 1.6rem;
            margin-bottom: 1rem;
          }
        }
      }

      .line {
        display: none;
      }
    }

    .image-wrapper {
      position: relative;
      overflow: visible;

      &::before {
        content: "";
        position: absolute;
        background: $projectRed;
        width: 0.5rem;
        height: 0;
        z-index: 1;
        bottom: -3rem;
        left: 3rem;
        transition: all 0.5s ease-in-out;
      }

      .inner-wrapper {
        min-width: 0;
        width: 100%;

        img {
          width: 100%;
        }
      }

      .line {
        &.image {
          position: absolute;
          top: 3rem;
          left: 3rem;
          width: calc(100% - 6rem);
          height: calc(100% + 11.2rem);
          pointer-events: none;
          z-index: 3;
          &::after,
          &::before {
            background: $projectRed;
            content: "";
            width: 0.5rem;
            height: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: all 0.5s;
          }

          &::before {
            height: 0;
            bottom: 18rem;
          }

          &::after {
            height: 0.5rem;
            width: 0;
            bottom: auto;
            top: 0;
            left: 0;
            transition-delay: 0.5s;
          }

          span {
            &::after,
            &::before {
              background: $projectRed;
              content: "";
              width: 0.5rem;
              height: 0;
              position: absolute;
              bottom: 0;
              left: 0;
            }

            &::before {
              position: absolute;
              bottom: auto;
              left: auto;
              right: 0;
              top: 0;
              transition-delay: 1s;
            }

            &::after {
              height: 0.5rem;
              width: 0;
              top: auto;
              left: auto;
              bottom: 0;
              right: 0;
              transition-delay: 1.5s;
            }
          }
        }
      }
    }

    .slide-down {
      display: none;
    }

    &.page-video {
      .text-with-line {
        padding: 4rem 0;

        .content-wrapper {
          .content {
            padding-right: 0;
          }
        }
      }

      .image-wrapper {
        .play-icon {
          display: none;
        }
      }
    }

    &.has-label {
      &.type-one {
        .image-wrapper {
          .line {
            &.image {
              display: none;
            }
          }
        }
      }
    }

    &.type-three {
      .content {
        margin: 0;
      }

      .text-with-line {
        padding: 4rem 0 0;
        padding-right: 0;
      }
    }

    &.animate {
      .image-wrapper {
        &::before {
          height: 7.7rem;
        }

        .line {
          &.image {
            &::before {
              height: calc(100% - 18rem);
            }

            &::after {
              width: 100%;
            }

            span {
              &::before {
                height: 100%;
              }

              &::after {
                width: 2rem;
              }
            }
          }
        }
      }

      &.no-image {
        .text-with-line {
          .content {
            h1 {
              &::after {
                height: 7.8rem;
              }
            }
          }
        }
      }
    }

    &.no-image {
      padding-left: 0;

      &.blue {
        padding-left: 2rem;
      }

      .text-with-line {
        .content {
          padding-left: 0;

          &::before {
            display: none;
          }

          h1 {
            margin-bottom: 11rem;
            position: relative;

            &::after {
              height: 0;
              position: absolute;
              width: 0.5rem;
              background: $projectRed;
              content: "";
              top: calc(100% + 1rem);
              left: 0;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }

      &.flush {
        padding: 0 3rem;
      }
    }
  }

  // sass-lint:disable-block class-name-format
  .basicLightbox {
    &.banner {
      .basicLightbox__placeholder {
        .video-wrapper {
          width: 100%;
        }

        iframe {
          height: auto;
          margin: auto 0;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

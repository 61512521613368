@use "../../styles/variables";

// COMPONENT - C03 Sub navigation
// DEFAULT CLASS - .sub-navigation
// CLASS VARIANTS -
// WIDE WEB

.subNavTertiary {
  min-width: 16rem;
  margin-left: 3rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background: transparent;
}

.sub-navigation {
  margin: 4rem 0;

  .sub-navigation-inner {
    color: white;
    background: variables.$projectBlue;
  }

  .subnav-wrapper {
    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 1.5rem 0;
    }

    a {
      &.light {
        max-width: 21.1rem;
        background: #e9e6e4;
        border-color: #e9e6e4;

        &:hover,
        &:focus {
          background: transparent;
        }
      }

      &.tertiary {
        min-width: 16rem;
        margin-left: 3rem;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        background: transparent;
      }
    }
  }

  .button-wrappers {
    display: flex;
    margin-left: 2rem;

    .message-wrapper {
      // @include message-popup;
      position: relative;
      display: flex;
      align-items: center;
      min-width: 2rem;
      margin-left: 0.5rem;

      .message {
        bottom: 5rem;
        right: -1rem;
      }
    }
  }

  .button-with-message {
    display: flex;
  }

  &.stick {
    padding-bottom: 6.5rem;

    .sub-navigation-inner {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 3;
    }

    .button-wrappers {
      .message-wrapper {
        .message {
          bottom: -5rem;

          &::after {
            top: -2rem;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .subnav-links {
    display: flex;
    justify-content: space-between;

    li {
      margin-left: 2.5rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      position: relative;

      a {
        position: relative;

        &::after {
          position: absolute;
          width: 100%;
          height: 0;
          content: "";
          left: 0;
          background: rgba(250, 250, 250, 0.6);
          bottom: -0.6rem;
        }
      }

      &.nav-label {
        display: none;

        & + li {
          margin-left: 0;
        }
      }

      &.active,
      &:focus,
      &:hover {
        a {
          &::after {
            height: 0.2rem;
          }
        }
      }
    }

    a {
      color: white;
      text-decoration: none;
      font-size: 1.2rem;
      width: 100%;
    }
  }
}

.subnav-header {
  margin-top: 4rem;

  .top-heading {
    h2 {
      font-size: 1.4rem;
      text-transform: uppercase;
      font-family: variables.$baseFontFamily;
    }
  }

  .title-with-intro {
    display: flex;
    margin-bottom: 4rem;

    .left-section {
      width: 50%;
      padding-right: 4.5rem;

      h3 {
        font-size: 4.8rem;
      }
    }

    .right-section {
      width: 50%;
      padding-left: 4.5rem;

      p {
        font-size: 2rem;
        margin-bottom: 3.5rem;
      }
    }
  }
}

@media all and (min-width: 870px) {
  .sub-navigation {
    .subnav-wrapper {
      // sass-lint:disable no-important
      height: auto !important;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .sub-navigation {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait},
#{variables.$mobile} {
  .sub-navigation {
    &.stick {
      padding-bottom: 6.5rem;

      .sub-navigation-inner {
        position: fixed;
        width: 100%;
        top: 0;
      }
    }

    .subnav-wrapper {
      height: 6.5rem;
      overflow: hidden;
      transition: height 0.5s ease-in-out;

      .inner-wrapper {
        display: block;
        width: 100%;

        a {
          &.light {
            max-width: 100%;
            margin-left: 0;
          }

          &.tertiary {
            span {
              &:last-child {
              }
            }
          }
        }

        .subnav-links {
          display: block;
          width: 100%;

          li {
            margin-left: 0;
            margin-bottom: 1.5rem;
            font-weight: 700;
            visibility: hidden;
            transition: visibility 0.5s;
            transition-delay: visibility 0.5s;

            a {
              font-size: 1.6rem;
            }

            &.nav-label {
              &.toggle {
                border-bottom: 1px solid rgba(250, 250, 250, 0.25);
              }

              display: block;
              visibility: visible;
              padding-bottom: 1rem;
              padding-right: 2rem;

              .tick {
                position: absolute;
                top: 0;
                right: 0;
                pointer-events: none;

                svg {
                  transform: rotate(90deg);
                  fill: white;
                  width: 0.8rem;
                  margin-right: 1rem;
                }
              }
            }

            &:focus,
            &:hover {
              a {
                &::after {
                  content: none;
                }
              }
            }

            .active {
              a {
                &::after {
                  content: "";
                }
              }
            }
          }
        }

        .button-wrappers {
          visibility: hidden;
          transition: visibility 0.5s;
          transition-delay: visibility 0.5s;
          margin-left: 0;
          flex-direction: column;
          border-top: 1px solid rgba(250, 250, 250, 0.25);
          padding-top: 2.5rem;
        }

        .button-with-message {
          margin-top: 1.7rem;
        }

        .message-wrapper {
          margin-left: auto;
        }
      }

      &.open {
        .inner-wrapper {
          .subnav-links {
            li {
              visibility: visible;

              &.nav-label {
                .tick {
                  svg {
                    transform: rotate(270deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .subnav-header {
    .title-with-intro {
      flex-direction: column;
      margin-bottom: 0;

      > .right-section,
      > .left-section {
        width: 100%;
        padding: 0;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .sub-navigation {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .sub-navigation {
  }
}

// COMPONENT - C03.1 Sub navigation

// DEFAULT CLASS - .sub-navigation-two-level

// CLASS VARIANTS -

// WIDE WEB
$secondaryNavHeight: 5.5rem;

.sub-navigation-two-level {
  margin: 0 -2rem;
  margin-bottom: 4rem;

  .sticky-wrap {
    background: variables.$projectDarkBlue;
    // margin: 0 -2rem;
    padding: 0 2rem;
    position: relative;
    transition: margin-bottom 0.3s ease-in-out;

    > .wrapper {
      align-items: stretch;
      display: flex;
      min-height: 6.5rem;
      justify-content: space-between;
    }
  }

  .mobile-only {
    display: none;
  }

  &.stick {
    padding-bottom: 6.5rem;

    .sticky-wrap {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 10;
    }

    .button-wrappers {
      .message-wrapper {
        .message {
          bottom: -5rem;

          &::after {
            top: -2rem;
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &.subnav-open {
    margin-bottom: calc(#{$secondaryNavHeight} + 4rem);
  }

  .subnav-links {
    display: flex;

    .d-hide {
      display: none;
    }

    li {
      z-index: 2;
      align-items: center;
      color: variables.$projectWhite;
      display: flex;
      margin: 0;
      padding: 0 1.8rem;

      &.mobile-only {
        display: none;
      }

      &.active {
        a {
          &::after {
            opacity: 1;
            background: variables.$projectWhite;
          }

          &:active,
          &:hover,
          &:focus {
            &::after {
              background: variables.$projectWhite;
              opacity: 1;
            }
          }
        }
      }

      &:nth-child(2) {
        padding-left: 0;

        &.has-subnav {
          padding-left: 1.8rem;
        }
      }

      &.has-subnav {
        > a,
        > span {
          color: variables.$projectWhite;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0.2rem 2rem 0 0;
          position: relative;
          text-decoration: none;

          &::after {
            background: variables.$projectWhite;
            content: "";
            display: block;
            height: 2px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 100%;
            width: 100%;
          }

          &:hover,
          &:focus {
            &::after {
              background: variables.$projectInteractiveNavy;
              opacity: 1;
            }
          }

          &:active {
            &::after {
              background: variables.$projectWhite;
              opacity: 1;
            }
          }
        }

        i {
          display: inline-block;
          height: 2.2rem;
          position: absolute;
          right: 0.5rem;
          top: 50%;
          transform: rotate(90deg) translateY(-50%);
          transform-origin: top;
          width: 1rem;

          svg {
            fill: variables.$projectWhite;
            height: 1rem;
            width: 100%;
          }
        }

        &.open > a {
          &::after {
            background: variables.$projectBlue;
            opacity: 1;
          }
        }

        &:hover,
        &.open {
          background: variables.$projectWhite;

          > a,
          > span {
            color: variables.$projectBlue;
          }

          svg {
            fill: variables.$projectBlue;
          }

          .secondary-nav {
            height: $secondaryNavHeight;
          }
        }
      }
    }

    a {
      color: variables.$projectWhite;
      font-size: 1.2rem;
      font-weight: 700;
      padding: 0.2rem 0;
      position: relative;
      text-decoration: none;

      &::after {
        background: variables.$projectWhite;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 100%;
        width: 100%;
      }

      &:hover,
      &:active,
      &:focus {
        &::after {
          background: variables.$projectInteractiveNavy;
          opacity: 1;
        }
      }
    }

    .secondary-nav {
      background: variables.$projectWhite;
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.15);
      height: 0;
      left: 2rem;
      margin: 0 -2rem;
      overflow: hidden;
      padding: 0 2rem;
      position: absolute;
      top: 100%;
      transition: height 0.2s ease-in-out;
      width: 100%;

      > .wrapper {
        height: 100%;
        overflow: hidden;
        position: relative;
      }

      &.has-arrows {
        .scroll-left,
        .scroll-right {
          display: block;
        }

        .secondary-nav-links {
          &.left-arrow {
            padding-left: 2.5rem;
          }

          &.right-arrow {
            padding-right: 2.5rem;
          }
        }
      }

      .scroll-left,
      .scroll-right {
        // sass-lint:disable-block no-duplicate-properties
        background: variables.$projectWhite;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          variables.$projectWhite 50%
        );
        border: 0;
        box-shadow: none;
        cursor: pointer;
        display: none;
        padding: 2.2rem 2rem 0;
        position: absolute;
        top: 50%;
        transform-origin: top;
        z-index: 11;

        &.hidden {
          visibility: hidden;
        }

        i {
          top: auto;
          transform: rotate(90deg) translateX(-50%);
        }

        .hidden {
          clip: rect(1px, 1px, 1px, 1px);
          clip-path: inset(50%);
          height: 1px;
          width: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
        }
      }

      .scroll-left {
        left: 0;
        transform: rotate(90deg);
      }

      .scroll-right {
        right: 0;
        transform: rotate(-90deg);
      }

      .secondary-nav-links {
        // sass-lint:disable-block no-misspelled-properties
        display: flex;
        height: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }

        li {
          display: inline-block;
          line-height: $secondaryNavHeight;

          &:first-child {
            padding-left: 0;
          }

          &:nth-child(2) {
            padding-left: 1.8rem;
          }

          &.mobile-only {
            display: none;
          }
        }

        a {
          color: variables.$projectBlue;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0.2rem 0;
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }
  }

  .button-wrappers {
    display: flex;
    align-items: center;
    margin: 0 -2rem;
    padding: 1.5rem 2rem;

    :global(.cta:not(.tertiary)) {
      white-space: nowrap;
    }
    :global(#download-brochure) {
      text-align: left;
    }

    .message-wrapper {
      cursor: pointer;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .sub-navigation-two-level {
  }
}

// MOBILE & TP
@media #{variables.$tablet-portrait},
#{variables.$mobile} {
  .sub-navigation-two-level {
    .sticky-wrap {
      overflow: hidden;
    }

    .subNavTertiary {
      max-width: 100%;
      margin-left: 0;
    }

    .button-wrappers {
      border-top: 1px solid hsla(0, 0%, 98%, 0.25);

      display: block;
      visibility: hidden;
    }
  }

  .sub-navigation-two-level {
    &.sticky-combo-wrap {
      .button-wrappers {
        display: block;
        transition: visibility 0.5s;
        transition-delay: visibility 0.5s;
        margin-left: 0;
        flex-direction: column;
        border-top: 1px solid rgba(250, 250, 250, 0.25);
        padding-top: 2.5rem;
      }
    }
    .mobile-only {
      display: block;
    }

    &.subnav-open {
      margin-bottom: 4rem;
    }

    .sticky-wrap {
      > .wrapper {
        display: block;
        height: 6.5rem;
        position: relative;
        transition: height 0.3s ease-in-out;
      }
    }

    &.open {
      .sticky-wrap {
        padding-bottom: 25px;
        > .wrapper {
          overflow: visible;
        }
      }

      .subnav-links {
        .d-hide {
          &::after {
            display: block;
          }

          i {
            transform: rotate(-90deg);
          }
        }

        li {
          opacity: 1;
          margin-bottom: 1.5rem;
          pointer-events: auto;
        }
      }

      // open
      .button-wrappers {
        display: block;
        margin-left: 0;
        visibility: visible;
        flex-direction: column;
      }
    }

    .button-with-message {
      margin-top: 1.7rem;
    }

    .message-wrapper {
      margin-left: auto;
    }

    .subnav-links {
      display: block;
      pointer-events: none;

      a {
        font-weight: bold;
      }

      .d-hide {
        display: block;
        height: 6.5rem;
        opacity: 1;
        outline: none;
        padding-top: 1.5rem;
        padding-right: calc(49% + 7rem);
        position: relative;
        pointer-events: auto;

        span {
          font-size: 1.2rem;
          font-weight: bold;
        }

        &::after {
          background: variables.$projectInteractiveNavy;
          bottom: 0;
          content: "";
          display: none;
          height: 0.1rem;
          left: -2rem;
          position: absolute;
          width: calc(100% + 4rem);
        }

        i {
          display: inline-block;
          height: 1.5rem;
          position: absolute;
          right: calc(49% + 5rem);
          top: 2rem;
          transform: rotate(90deg);
          transition: all 0.2s linear;

          svg {
            height: 100%;
            fill: variables.$projectWhite;
          }
        }
      }

      li {
        padding: 0;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;

        &.mobile-only {
          display: block;
        }

        &.active {
          a {
            border-bottom: 2px solid variables.$projectWhite;
          }
        }

        &:nth-child(2) {
          &.has-subnav {
            padding: 0;
          }
        }

        &:last-child {
          a {
            border-bottom: 0;
          }
        }

        &.has-subnav {
          > a,
          > span {
            border-bottom: 1px solid variables.$projectInteractiveNavy;
            display: block;
            font-weight: bold;
            padding: 1.6rem 0;
            width: 100%;

            &::after {
              display: none;
            }
          }

          i {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &.open,
          &:hover {
            background: variables.$projectBlue;

            > a,
            > span {
              color: variables.$projectWhite;

              i {
                svg {
                  fill: variables.$projectWhite;
                }
              }
            }

            .secondary-nav {
              height: 100%;
            }
          }

          &.open {
            .secondary-nav {
              pointer-events: all;
              transform: translateX(0);
            }
          }
        }

        a {
          display: block;
          width: 100%;

          &::after {
            display: none;
          }
        }
      }

      .secondary-nav {
        box-shadow: none;
        height: 100%;
        margin: 0;
        left: -2rem;
        pointer-events: none;
        top: 0;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        width: calc(100% + 4rem);
        z-index: 2;

        > .wrapper {
          overflow: visible;
        }

        &.has-arrows {
          .scroll-left,
          .scroll-right {
            display: none;
          }

          .secondary-nav-links {
            padding: 0 2rem 6rem 0;
          }
        }

        .back-link {
          color: variables.$projectBlue;
          cursor: pointer;
          display: block;
          font-weight: bold;
          padding: 1.6rem 0;
          position: relative;
          width: 100%;

          i {
            margin-right: 1rem;
            position: relative;
            transform: rotate(180deg) translateY(10%);
            top: 2.5rem;
          }

          a {
            border-bottom: 0;
            color: variables.$projectBlue;
          }

          &::after {
            background: variables.$projectInteractiveNavy;
            bottom: 0;
            content: "";
            display: block;
            height: 0.1rem;
            left: -2rem;
            position: absolute;
            width: calc(100% + 4rem);
          }
        }

        .secondary-nav-links {
          display: block;
          margin-right: -2rem;
          overflow-x: hidden;
          overflow-y: scroll;
          padding: 0 2rem 6rem 0;

          li {
            display: block;
            padding: 0;

            &:nth-child(2) {
              padding: 0;
            }

            &:last-child {
              a {
                border-bottom: 0;
              }
            }

            &.mobile-only {
              display: block;

              > a {
                > strong {
                  color: #001e62;
                  font-size: 1.4rem;
                  font-weight: 700;
                }
              }
            }
          }

          a {
            border-bottom: 1px solid variables.$projectInteractiveNavy;
            display: block;
            font-weight: bold;
            padding: 1.6rem 0;
            width: 100%;
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .sub-navigation-two-level {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .sub-navigation-two-level {
  }
}

// COMPONENT - CXX Subnav combo

// DEFAULT CLASS - .subnav-combo

// CLASS VARIANTS -

// WIDE WEB

.subnav-combo {
  &.stick-combo {
    .sticky-combo-wrap {
      left: 0;
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 10;
    }

    .programme-details-bar-sticky {
      .select-container {
        pointer-events: none;
      }
    }
    .sticky-wrap {
      margin: 0 2rem;
    }
  }

  .programme-details-bar-sticky {
    margin-bottom: 0;
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .subnav-combo {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .subnav-combo {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .subnav-combo {
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .subnav-combo {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .subnav-combo {
    &.stick-combo {
      .sticky-combo-wrap {
        // padding: 0 2rem;
      }

      .programme-details-bar-sticky {
        .sticky-wrap {
          padding-top: 0;
        }

        .select-container {
          display: none;
        }
      }
    }
  }
}

li.nav-label .tick {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}

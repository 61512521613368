@use "../../styles/variables.scss";

.programme-calendar {
  margin: 8rem 0;

  .search-results {
    .programmes {
      height: 0;
      overflow: hidden;
      display: none;
    }

    .no-results {
      font-size: 1.6rem;
      margin-bottom: 4rem;
    }

    .date {
      font-size: 2.4rem;
      font-weight: bold;
      padding: 2rem 0;
      border-top: 1px solid variables.$projectBlue;
      font-family: variables.$headingFontFamily;
      position: relative;
      cursor: pointer;
      display: block;
      text-decoration: none;

      span {
        font-weight: normal;
        font-family: variables.$baseFontFamily;
        display: block;
        margin-top: 0.5rem;
      }

      &::after {
        content: "";
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAxZTYyIiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjAwMDAwMCwgLTYuMDAwMDAwKSI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxMCA2IDguNTkgNy40MSAxMy4xNyAxMiA4LjU5IDE2LjU5IDEwIDE4IDE2IDEyIj48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
        right: 2rem;
        top: 55%;
        background-repeat: no-repeat;
        transform: translateY(-50%) rotate(90deg);
      }

      &[data-open="true"] {
        // border-bottom: 1px solid variables.$projectBlue;

        + .programmes {
          height: auto;
          margin-bottom: 3rem;
          display: block;
        }
      }
    }

    .date-group {
      &:last-child {
        border-bottom: 1px solid variables.$projectBlue;
      }
    }
  }

  .filter-clear {
    position: relative;
    margin-top: 4rem;
    margin-bottom: 4rem;

    .clear-all {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.8rem;
      font-weight: bold;
      text-decoration: none;

      svg {
        fill: variables.$projectBlue;
        width: 1.6rem;
        height: 1.6rem;
        transform: translateY(1px) translateX(-3px);
      }

      &:hover,
      &:focus {
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .filter {
      display: flex;
      font-size: 1.2rem;
      margin-right: 16rem;
      align-items: baseline;

      .items {
        display: flex;
        flex-wrap: wrap;
      }

      .option {
        border: 1px solid #d7d2cb;
        padding: 0.2rem 0.75rem;
        font-size: 1.4rem;
        margin-left: 2rem;
        text-decoration: none;
        margin-top: 1rem;

        svg {
          width: 1rem;
          height: 1rem;
          fill: variables.$projectBlue;
          transform: translateY(1px);
        }

        &:hover,
        &:focus {
          background-color: #001e62;
          color: white;
          text-decoration: none;
          border: 1px solid #001e62;
          cursor: pointer;

          svg {
            fill: white;
          }
        }
      }
    }
  }

  .show-filters {
    display: none;
  }

  .filters {
    > .inner {
      display: flex;
    }

    .filter {
      width: 100%;
      margin-right: 2rem;
      position: relative;

      &:focus {
        outline: 1px solid rgb(94, 158, 214);
      }

      .header {
        border: 0.1rem solid #001e62;
        background: transparent;
        padding: 1.5rem 2rem 1rem;
        position: relative;
        cursor: pointer;
        font-size: 1.5rem;
        color: variables.$projectBlue;

        &::after {
          content: "";
          position: absolute;
          width: 1.2rem;
          height: 1.2rem;
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAxZTYyIiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjAwMDAwMCwgLTYuMDAwMDAwKSI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxMCA2IDguNTkgNy40MSAxMy4xNyAxMiA4LjU5IDE2LjU5IDEwIDE4IDE2IDEyIj48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
          right: 2rem;
          top: 55%;
          background-repeat: no-repeat;
          transform: translateY(-50%) rotate(90deg);
        }
      }

      .values {
        padding: 0;
        overflow: hidden;
        width: 100%;
        position: absolute;
        background-color: #ebe8e5;
        z-index: 1;
        height: 0;
        display: none;

        .value {
          padding: 0 20px;
          line-height: 22px;
          position: relative;
          font-size: 1.6rem;
          min-height: 2rem;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
          margin: 1rem 0;

          &::after {
            display: block;
            content: attr(data-label);
            font-weight: bold;
            height: 0;
            overflow: hidden;
            padding-left: 4rem;
            visibility: hidden;
            font-size: 1.5rem;
          }

          &:first-child {
            margin-left: 0;
          }

          &:focus {
            outline: none;
          }

          .tick {
            display: block;
            width: 2.2rem;
            height: 2.2rem;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 2rem;
            background-color: transparent;
            border: 0.1rem solid variables.$projectBlue;
            transition: (
              background-color 0.15s ease-in-out,
              border-color 0.15s ease-in-out
            );

            &::after {
              content: "";
              width: 1.3rem;
              height: 0.7rem;
              position: absolute;
              top: 0.5rem;
              left: 0.3rem;
              border: 0.3rem solid variables.$projectBlue;
              border-top: 0;
              border-right: 0;
              background: transparent;
              transition: (border-color 0.15s ease-in-out);
              opacity: 0;
              transform: rotate(-45deg);
            }
          }

          label {
            padding-left: 4rem;
            padding-top: 0.3rem;
            outline: none;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &:hover {
            label {
              font-weight: bold;
            }
          }
        }

        [type="checkbox"],
        [type="radio"] {
          position: absolute;
          left: 0.2rem;
          top: 0.2rem;
          opacity: 0;

          &:active,
          &:focus,
          &:hover {
            + .tick,
            + input + .tick {
              border-color: variables.$projectBlue;
              border-width: 0.2rem;
            }
          }

          &:focus,
          &:hover {
            label {
              font-weight: 700;
            }
          }

          &:focus {
            & ~ label {
              font-weight: 700;
            }
          }

          &:checked {
            + .tick,
            + input + .tick {
              background-color: variables.$projectBlue;
              border: 0.1rem solid variables.$projectBlue;

              &::after {
                border-color: variables.$projectWhite;
                opacity: 1;
              }
            }

            &:active,
            &:focus,
            &:hover {
              + .tick,
              + input + .tick {
                border-color: variables.$projectBlue;
              }
            }

            &[disabled] {
              + .tick,
              + input + .tick {
                background-color: variables.$projectLightGrey;
                border-color: variables.$projectLightGrey;

                &::after {
                  opacity: 1;
                }
              }

              ~ label {
                color: variables.$projectGrey;
              }
            }
          }

          &[disabled] {
            &:active,
            &:focus,
            &:hover {
              + .tick,
              + input + .tick {
                + label {
                  text-decoration: initial;
                }
              }
            }

            + .tick,
            + input + .tick {
              pointer-events: none;
              background-color: variables.$projectLightGrey;
              border-color: variables.$projectLightGrey;

              &::after {
                opacity: 0;
              }

              + label {
                cursor: default;
              }
            }
          }
        }

        label {
          display: block;
          position: relative;
          padding-left: 3rem;
          cursor: pointer;
          text-indent: 0;
          font-size: 1.5rem;

          h6 {
            margin-top: 0;
            font-size: 1.5rem;
          }

          a {
            font-weight: normal;
          }
        }
      }

      &[data-open="true"] {
        .values {
          height: auto;
          border: 1px solid #001e62;
          border-top: 0;
          display: block;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .programme-calendar {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .programme-calendar {
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .programme-calendar {
    .filters {
      flex-direction: column;
    }
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile} {
  .programme-calendar {
    .filter-clear {
      .clear-all {
        position: static;
        transform: none;
        margin-top: 2rem;
        width: 100%;
        text-align: center;
        display: block;
      }

      .filter {
        display: none;
      }
    }

    .show-filters {
      display: block;
      position: relative;

      &[data-count] {
        &::after {
          content: attr(data-count);
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(1rem);
          background-color: variables.$projectLightGrey;
          border-radius: 50%;
          width: 2.2rem;
          height: 2.2rem;
          text-align: center;
          line-height: 1.4;
          color: variables.$projectBlue;
          font-weight: bold;
        }
      }
    }

    .filters {
      height: 0;
      transition: height 0.3s;
      overflow: hidden;

      > .inner {
        flex-direction: column;
      }

      .filter {
        outline: 0;
        width: 100%;
        margin: 1rem 0;

        &:focus {
          outline: 0;
        }

        .header {
          border: 0;
          border-bottom: 1px solid variables.$projectBlue;
          padding-left: 0;

          &[data-count] {
            &::before {
              content: attr(data-count);
              position: absolute;
              right: 4.5rem;
              top: 50%;
              transform: translateY(-50%);
              background-color: variables.$projectLightGrey;
              border-radius: 50%;
              width: 2.2rem;
              height: 2.2rem;
              text-align: center;
              line-height: 1.4;
              color: variables.$projectBlue;
              font-weight: bold;
            }
          }
        }

        .values {
          position: static;
          border: 0;
          margin-top: 1rem;
        }

        &[data-open="true"] {
          .values {
            border: 0;
          }
        }
      }
    }
  }
}

.course-search-results {
  + .course-search-results {
    margin-top: 2rem;
  }

  &.blue {
    color: white;

    .vertical-accordion {
      color: white;

      .accordion-drop {
        .section-row-wrapper {
          background: variables.$projectBone;
          color: variables.$projectBlue;

          .message-wrapper {
            &:focus,
            &:hover {
              &::before {
                background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23001e62%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
              }
            }
          }

          .info {
            .sub-text {
              color: variables.$projectBlue;
              font-weight: 700;
            }
          }
        }

        .description {
          p {
            color: variables.$projectBlue;
          }
        }
      }
    }
  }

  &.accordion {
    display: flex;
    justify-content: space-between;

    ul {
      margin: 0 -1rem;
    }

    .vertical-accordion {
      max-width: 36.5rem;
      padding: 1rem;
    }
  }

  .dt-hide {
    display: none;
  }

  .application-box {
    display: flex;

    > div {
      padding: 3rem;
    }

    .left-section {
      width: 55%;
      background: white;
      position: relative;

      .title-link {
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      h4 {
        line-height: 4.5rem;
        margin-bottom: 1.8rem;
      }

      > p {
        margin-bottom: 2rem;
      }

      > .form {
        margin-bottom: 2.5rem;

        .checkbox {
          color: variables.$projectBlue;
          float: right;
          margin-right: 1rem;
          .tick {
            left: 0;
          }

          label {
            padding-top: 0.2rem;
            font-weight: 700;
            outline: 0;
            display: inline;
          }
        }
      }

      .apply-wrapper {
        position: absolute;
        bottom: 3rem;
        width: 90%;
      }

      .button-with-link {
        display: fleX;
        align-items: center;
        align-content: flex-start;
        justify-content: space-between;

        .cta {
          // sass-lint:disable-block no-important
          max-width: 22rem;
          margin-right: 1rem;
          color: white !important;

          &:hover {
            color: variables.$projectBlue !important;
            text-decoration: none;
          }

          &.tertiary {
            margin-right: 0;
            color: variables.$projectBlue !important;
          }
        }
      }

      .button-margin {
        margin-bottom: 9rem;
      }

      .tag-info {
        padding-right: 4rem;
        margin-bottom: 2.5rem;
        font-size: 1.2rem;
        color: variables.$projectBlue;

        .message-wrapper {
          // @include message-popup;
          top: -0.5rem;

          &::before {
            // background-color: white;
            background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22\a%20%20%20viewBox%3D%222%202%2020%2020%22%20enable-background%3D%22new%200%200%2030%2030%22%20xml%3Aspace%3D%22preserve%22%3E\a%3Cpath%20fill%3D%22%237F8EB0%22%20d%3D%22M11%2017h2v-6h-2v6zm1-15C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208zM11%209h2V7h-2v2z%22%2F%3E\a%3C%2Fsvg%3E");
          }

          &:not(.inactive) {
            &:focus,
            &:hover {
              &::before {
                background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
              }
            }
          }

          &:focus,
          &:hover {
            &::before {
              background: url("data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%2020%2020%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Ctitle%3EError%3C%2Ftitle%3E%0A%20%20%20%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M9%2C13%20L11%2C13%20L11%2C15%20L9%2C15%20L9%2C13%20Z%20M9%2C5%20L11%2C5%20L11%2C11%20L9%2C11%20L9%2C5%20Z%20M0%2C10%20C0%2C15.52%204.47%2C20%209.99%2C20%20C15.52%2C20%2020%2C15.52%2020%2C10%20C20%2C4.48%2015.52%2C0%209.99%2C0%20C4.47%2C0%200%2C4.48%200%2C10%20Z%22%20id%3D%22path-1%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E");
            }
          }

          .message {
            color: variables.$projectBlue;
            width: 18.7rem;
            min-width: 10.3rem;
            right: -1rem;
            bottom: 3rem;
          }
        }

        .tag-label {
          font-weight: 700;
          margin-right: 0.5rem;
        }
      }
    }

    .right-section {
      background: variables.$projectBlue;
      color: white;
      width: 45%;

      .section-row-wrapper {
        margin: 0;
        flex-flow: column;
      }

      .inner-wrapper {
        height: 100%;
        position: relative;
      }

      .section-row {
        @include variables.section-row;

        .delivery-info {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
        }

        .delivery-text {
          ul {
            margin-bottom: 0;
          }

          li {
            margin-bottom: 0.6rem;
          }
        }

        .info {
          padding-right: 0.5rem;

          &:last-child {
            padding-right: 0;
            flex-direction: column;
            line-height: 1.5;
            flex-basis: 100%;
          }
        }
      }

      .rich-text {
        margin-bottom: 0;

        ul {
          margin-bottom: 0;

          li {
            line-height: 1.5;
            margin-bottom: 0.6rem;
          }
        }
      }
    }
  }

  .vertical-accordion {
    // @include vertical-accordion;

    li {
      &:last-child {
        a {
          &.toggle-link {
            padding: 0 5rem 0 0;
          }
        }
      }
    }

    .accordion-drop {
      display: block;
      .section-row-wrapper {
        .message-wrapper {
          &:not(.inactive) {
            &:focus,
            &:hover {
              &::before {
                background: variables.$lighInfoIcon;
              }
            }
          }
        }
      }
    }
  }
}
// LESS THAN MAX WIDTH
@media #{variables.$less-than-max-width} {
  .course-search-results {
    &.accordion {
      max-width: none;
      margin: 0 -1rem;

      .vertical-accordion {
        max-width: 36.5rem;
        padding: 1rem;
      }
    }
  }
}
// COMPACT WEB
@media #{variables.$compact-web} {
  .course-search-results {
  }
}
// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .course-search-results {
  }
}
// MOBILE
@media #{variables.$mobile} {
  .course-search-results {
    .application-wrapper,
    .vertical-accordion {
      margin-bottom: 3rem;
      margin-left: 0;
      max-width: none;
      padding: 0 1rem;

      li {
        .accordion-drop {
          .content {
            padding-top: 2.8rem;
          }

          .description {
            p {
              margin-bottom: 2rem;
            }
          }

          .section-row-wrapper {
            .section-row {
              .info {
                // width: 100%;
              }
            }

            .button-wrapper {
              width: 100%;
            }
          }
        }
      }
    }

    .dt-hide {
      display: block;
    }

    .application-box {
      display: block;
      box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.41);

      > div {
        padding: 2rem;
      }

      .left-section,
      .right-section {
        width: auto;
      }

      .left-section {
        .button-margin {
          margin-bottom: 6rem;
        }

        h4 {
          line-height: 1.2;
          margin-bottom: 2rem;
        }

        > p {
          font-size: 1.2rem;
        }

        .button-with-link {
          display: none;
        }

        > .form {
          margin-bottom: 1rem;

          .checkbox {
            color: variables.$projectBlue;
            float: none;
            margin-right: 1rem;
          }
        }
      }

      .right-section {
        .section-row {
          .delivery-info {
            -webkit-line-clamp: 8;
          }
          .info {
            font-size: 1.2rem;
            padding-top: 0.3rem;

            .sub-text {
              margin-top: 0.5rem;
            }
          }
        }

        .inner-wrapper {
          padding-bottom: 0;
        }
      }

      .tag-info {
        font-size: 1.2rem;
        margin-bottom: 2rem;
        color: variables.$projectBlue;

        .tag-label {
          font-weight: 700;
          color: variables.$projectBlue;
        }
      }

      .m-hide {
        display: block;

        > span {
          display: none;
        }
      }
    }

    &.accordion {
      margin: 0;
      flex-direction: column;

      .vertical-accordion {
        max-width: none;
        padding: 0;
      }
    }
  }
}

.centered {
  text-align: center;
}

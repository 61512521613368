@use "../../styles/variables.scss";

.editors-choice {
  overflow: hidden;

  .left-col {
    padding-right: 0;
    .editors-choice--large {
      height: 100%;
    }
  }

  .right-col {
    padding-left: 0;

    .editors-choice {
      &--medium {
        height: 100%;
      }
    }
  }

  .wrapper-wide-restricted & {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-bottom: 8rem;
  }

  .tile-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .editor-choice-tile {
    position: relative;

    .tile {
      &-image {
        position: relative;
        z-index: 1;
      }

      &-content {
        background-color: variables.$projectWhite;
        padding: 1.8rem 2.5rem 0.6rem;
        position: relative;
        height: 100%;

        :global(.row) {
          margin: 0 0 2.4rem;
        }
      }

      &-author {
        color: variables.$projectGrey;
        font-family: variables.$baseFontFamily;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      &-heading {
        a {
          color: variables.$projectRed;
          font-family: variables.$headingFontFamily;
          position: relative;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }

      &-date {
        font-family: variables.$baseFontFamily;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-right: 1.6rem;
      }

      &-description {
        display: block;
      }
    }

    p {
      font-size: 2rem;
      line-height: 3rem;

      &:last-of-type {
        margin-bottom: 2.3rem;
      }
    }

    .tag {
      a {
        font-size: 1.2rem;
        padding: 0.3rem 0.6rem 0.2rem;
      }
    }

    .date-indicator {
      background-color: variables.$projectBlue;
      box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5);
      color: variables.$projectWhite;
      font-family: variables.$baseFontFamily;
      font-size: 2.4rem;
      height: 4.1rem;
      line-height: 1.9rem;
      margin-bottom: 1.9rem;
      padding: 0.4rem 0;
      text-align: center;
      width: 3.6rem;
      position: absolute;
      top: 0;
      right: 2.1rem;
      z-index: 1;

      span {
        display: block;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }

    &.blue {
      height: 100%;

      .tile {
        &-heading {
          a {
            color: variables.$projectWhite;
          }
        }

        &-content {
          background-color: variables.$projectBlue;
        }

        &-author,
        &-date {
          color: variables.$projectSantaGray;
          display: block;
        }
      }

      p {
        color: variables.$projectWhite;
      }

      .tag {
        a {
          color: variables.$projectWhite;

          &:hover,
          &:focus {
            background-color: variables.$projectWhite;
            border-color: variables.$projectWhite;
            color: variables.$projectBlue;
          }
        }
      }
    }

    &:hover {
      .icon {
        background-color: rgba(variables.$projectBlue, 0.95);
      }
    }
  }

  &--header {
    background-color: variables.$projectBlue;
    color: variables.$projectWhite;
    padding: 5.8rem 0;
    text-align: center;

    .tile-header {
      font-size: 6rem;
      margin-bottom: 2.4rem;
    }

    .tile-header,
    p {
      max-width: 74rem;
      margin: 0 auto 1.2rem;
    }
  }

  &--image {
    min-height: 100%;
    height: auto;
    width: 100%;
    position: relative;

    &.mobile-only {
      display: none;
    }

    .editor-choice-tile {
      height: 100%;
      width: 100%;
      position: absolute;

      .tile-image {
        z-index: 0;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        height: 100%;

        img {
          height: 100%;
          width: 100%;
          transition: transform 0.3s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &--small {
    &:nth-of-type(2) {
      &::before {
        border-top: 0.1rem solid #ebe8e5;
        content: "";
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 90%;
        z-index: 1;
      }

      .tile-content {
        padding-bottom: 2.2rem;
      }
    }

    &.desktop-only {
      display: block;
    }

    &.mobile-only {
      display: none;
    }
  }

  // Large tile
  &--large {
    position: relative;

    .editor-choice-tile {
      display: flex;
      flex-direction: column;
      height: 100%;

      .icon-on-image {
        height: 10rem;
        width: 10rem;

        span {
          height: 4rem;
          width: 4rem;
        }
      }

      .tile {
        &-content {
          height: 100%;
          margin-left: 6rem;
          position: relative;
          z-index: 1;
          padding: 4.2rem;

          :global(.row) {
            margin-bottom: 0.7rem;
          }

          p {
            &:last-of-type {
              margin-bottom: 1.4rem;
            }
          }
        }

        &-heading {
          font-size: 4.8rem;
          font-weight: bold;
          line-height: 5.8rem;
          margin-bottom: 1rem;
        }

        &-date {
          margin-right: 3.6rem;
        }
      }

      &.is--event {
        .tile {
          &-content {
            padding-top: 4.5rem;
            padding-left: 4.5rem;
          }
        }

        .date-indicator {
          left: 4.4rem;

          span {
            font-size: 1rem;
            line-height: 1.6rem;
            margin-bottom: -0.1rem;
          }
        }
      }
    }
  }

  // Small tile
  &--small,
  &--medium {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .editor-choice-tile {
      .tile {
        &-image {
          left: 2.5rem;
          position: absolute;
          top: -1.6rem;
          overflow: hidden;

          img {
            max-width: 20.3rem;
            transition: transform 0.3s;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        &-heading-container {
          position: relative;
        }

        &-heading {
          color: variables.$projectRed;
          font-family: variables.$baseFontFamily;
          font-size: 2rem;
          font-weight: bold;
          line-height: 2.6rem;
          margin-bottom: 0.8rem;

          a {
            font-family: variables.$baseFontFamily;
          }
        }

        &-content {
          :global(.row) {
            margin-bottom: 0.7rem;
          }
        }

        &-date {
          margin-right: 2rem;
        }
      }

      + .editor-choice-tile {
        &:not(.blue) {
          .tile-content {
            padding-bottom: 2.2rem;
          }

          &::before {
            border-top: 0.1rem solid variables.$projectBone;
            content: "";
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            width: 90%;
            z-index: 1;
          }
        }
      }

      // Podcast/Video heading bg
      &.is--podcast,
      &.is--video,
      &.is--event {
        &:not(.blue) {
          .tile {
            &-content {
              padding-right: 8rem;
            }
          }
        }
      }

      // Blue Variant
      &.blue {
        .tile {
          &-content {
            padding-top: 13.2rem;
          }
        }

        &.is--event {
          .date-indicator {
            right: 1.6rem;
            z-index: 1;
          }
        }
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: rgba(variables.$projectBlue, 0.8);
    border-radius: 50%;
    font-size: 0;
    transform: translate(-50%, -50%);
    width: 6.8rem;
    height: 6.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    & + .tile-heading {
      margin-right: 4rem;
    }

    svg {
      fill: variables.$white;
      stroke: variables.$white;
    }

    &.icon-podcast svg {
      width: 4rem;
    }

    &.icon-play svg {
      width: 3rem;
    }

    &.icon-medium,
    &.icon-small {
      width: 3.4rem;
      height: 3.4rem;
      transform: unset;
      top: unset;
      left: unset;
      bottom: 1.2rem;
      right: 1.2rem;

      &.icon-podcast svg {
        width: 2.4rem;
      }

      &.icon-play svg {
        margin-left: 0.3rem;
        width: 1.8rem;
      }
    }

    &.icon-small {
      top: 0;
      bottom: unset;
      right: 0;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .editors-choice {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .editors-choice {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .editors-choice {
    display: flex;
    flex-direction: column-reverse;
    margin-left: -2rem;
    margin-right: -2rem;

    .left-col {
      padding-right: 1rem;

      .editors-choice--large {
        height: auto;
      }
    }

    .right-col {
      padding-left: 1rem;
      margin-left: 0;

      .editors-choice {
        &--small {
          border-top: 0;
        }

        &--medium {
          height: auto;
        }
      }
    }

    .wrapper-wide-restricted & {
      padding-bottom: 4rem;
    }

    .tile-section {
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
    }

    .editor-choice-tile {
      .tile {
        &-content {
          padding: 2rem;
          width: 90%;
          margin: 0 auto 1rem;
          height: auto;
        }

        &-heading {
          font-size: 2.6rem;
          line-height: 3.3rem;
        }

        &-date {
          margin-bottom: 0.5rem;
        }

        &-description {
          display: none;
        }
      }

      &.blue {
        .tile {
          &-content {
            margin-top: -4.5rem;
            padding: 2.5rem 3.1rem 1rem;
          }

          &-date,
          &-author {
            display: inline-block;
            width: auto;
          }
        }
      }
    }

    &--header {
      padding: 3.8rem 0;
      order: 1;

      .tile-header {
        font-size: 4rem;
        margin-bottom: 2.4rem;
      }

      .tile-header,
      p {
        padding: 0 2.4rem;
      }
    }

    &--image {
      display: none;
      min-height: auto;

      &.mobile-only {
        display: block;
      }

      .editor-choice-tile {
        position: relative;

        .tile-image {
          picture {
            width: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &--large {
      max-width: 100%;

      &.mobile-only {
        display: block;
      }

      .editor-choice-tile {
        .tile {
          &-content {
            margin-top: -6.5rem;
            padding: 2rem 3.1rem 1.1rem;
          }

          &-date {
            margin-right: 2rem;
          }
        }

        &.is--podcast,
        &.is--video {
          .tile-image {
            &::after {
              height: 6rem;
              margin: 0;
              width: 6rem;
            }
          }
        }

        &.is--event {
          position: static;

          .tile-content {
            padding-top: 2.4rem;
            position: static;
          }

          .date-indicator {
            left: auto;
            right: 5.6rem;
            top: -6.5rem;
            z-index: 1;
          }
        }
      }
    }

    &--medium {
      .editor-choice-tile {
        .tile {
          &-image {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            z-index: auto;

            img {
              max-width: 100%;
              width: 100%;
            }
          }

          &-heading {
            font-size: 2rem;
            line-height: 2.6rem;
            margin-bottom: 1.6rem;
          }
        }
      }
    }

    &--small {
      display: flex;
      max-width: 100%;
      min-width: auto;
      background-color: variables.$projectWhite;
      width: 90%;
      margin: auto;

      &.desktop-only {
        display: none;
      }

      &.mobile-only {
        display: block;
        width: 90%;
        margin: auto;

        .tile-content {
          margin: 0;
          padding: 2rem 3.1rem 5.5rem;
        }
      }

      .editor-choice-tile {
        .tile {
          &-content {
            margin-bottom: 1rem;
            width: 100%;
            padding: 2.5rem 3.1rem 1rem;
          }

          &-heading {
            font-size: 2rem;
            line-height: 2.6rem;
            margin-bottom: 0.7rem;
          }
        }

        &.is--event {
          .tile {
            &-content {
              padding-top: 3.5rem;
            }
          }
        }

        &.blue {
          border-top: 0;

          .tile {
            &-image {
              left: auto;
              position: relative;
              top: 4rem;

              img {
                margin: 0 auto;
              }
            }

            &-content {
              margin-top: -8.9rem;
              padding-bottom: 1.3rem;
              padding-top: 15.5rem;
            }
          }

          &.is--event {
            .date-indicator {
              left: auto;
              right: 6.8rem;
            }
          }
        }

        + .editor-choice-tile {
          &:not(.blue) {
            &::before {
              width: 90%;
            }

            .tile {
              &-content {
                padding-top: 2.6rem;
                padding-bottom: 3.4rem;
              }
            }
          }
        }

        &.is--event,
        &.is--podcast,
        &.is--video {
          &:not(.blue) {
            .tile {
              &-content {
                padding-right: 6.5rem;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .icon {
      &.icon-medium {
        top: 50%;
        left: 50%;
        bottom: unset;
        right: unset;
        transform: translate(-50%, -50%);
        width: 6.8rem;
        height: 6.8rem;

        &.icon-podcast svg {
          width: 4rem;
        }

        &.icon-play svg {
          width: 3rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .editors-choice {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .editors-choice {
  }
}

@use "../../styles/variables.scss";

.events-details {
  position: relative;

  .spinner {
    padding-top: 50px;
    text-align: center;
  }

  .offsetTop1 {
    padding-top: 5.6rem;
  }

  .desktop-only {
    padding: 5.6rem 0;
  }

  &.registration-form {
    .desktop-only {
      width: 50%;
      padding: 5.6rem 0;
      .col-12 {
        display: block;
        max-width: 100%;

        .unit-section {
          padding: 0;
          margin-top: 30px;
        }
      }

      .action-bar {
        &:before {
          background: transparent;
        }
      }
    }
    .form {
      .section-heading,
      .sub-heading {
        color: variables.$projectBlue;
        font-family: variables.$headingFontFamily;
        font-size: 3rem;
        line-height: 3.8rem;
        margin-bottom: 2.4rem;
      }

      .fields-floated {
        .field-row {
          display: flex;
          flex-direction: row;
          margin-bottom: 1.6rem;

          &.edit-details {
            padding-bottom: 3.4rem;
            margin-bottom: 3.9rem;
            margin-top: 3.4rem;
            border-bottom: 0.1rem solid variables.$projectLightGrey;

            a {
              width: 22.1rem;
            }
          }

          label {
            flex-basis: 25%;
            max-width: 25%;
            color: variables.$projectBlue;
            font-family: variables.$baseFontFamily;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 700;
            padding-right: 1rem;

            + p {
              flex-basis: 75%;
              max-width: 75%;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .cu-wrapper {
      display: flex;
      flex-direction: row;

      .form-container {
        position: relative;
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 2rem;

        &:before {
          content: "";
          width: 200%;
          height: auto;
          display: block;
          background-color: variables.$projectBone;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
        }

        .inner-container {
          position: relative;
        }
      }
    }
  }

  background: variables.$projectWhite;
  margin: 0 -2rem;

  @at-root .white-grey-split & {
    padding-bottom: 3.8rem;
    margin: 0 7rem 0 0;

    > div {
      margin: 0;
    }

    .event {
      &-address {
        margin-top: 4.3rem;
      }

      &-speakers {
        margin-top: 2.5rem;
      }

      &-information {
        margin-top: 2.5rem;
      }

      &-contact {
        margin-top: 2.5rem;
      }
    }
  }

  .mobile-only {
    display: none;
  }

  .label {
    color: variables.$projectGrey;
    margin: 0 0 1rem;
  }

  i {
    margin-right: 0.5rem;
    position: relative;
    top: -0.1rem;

    svg {
      fill: variables.$projectInteractiveNavy;
      height: 20px;
      vertical-align: middle;
      width: 20px;
    }
  }

  .small {
    margin-bottom: 0;
  }

  h3 {
    @include variables.line-height(38, 30);
    font-size: 3rem;
    margin: 0 0 2.6rem;
  }

  a {
    @include variables.line-height(24, 16);
    font-size: 1.6rem;
    font-weight: normal;
  }

  .icon-external {
    svg {
      fill: variables.$projectBlue;
    }
  }

  .two-col {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex-basis: 50%;
      margin-bottom: 2.8rem;
      max-width: 50%;
      min-width: 50%;
      padding: 0 2rem;
    }
  }

  dl {
    dt {
      color: variables.$projectBlue;
      padding-right: 0.25rem;
    }

    dd {
      margin-bottom: 2.4rem;
    }
  }

  .directions {
    font-size: 1.5rem;
  }

  .essential-info {
    dt,
    dd {
      display: inline;
    }

    dd {
      &::after {
        content: "";
        display: block;
      }
    }
  }

  .link-list {
    a {
      font-size: 1.5rem;
      font-weight: bold;
      word-break: break-all;
    }
  }

  .action-bar {
    margin-top: 4.4rem;
    padding: 4.4rem 0 0;
    position: relative;

    &::before {
      background: variables.$projectInteractiveNavy;
      content: "";
      display: block;
      height: 1px;
      left: 2rem;
      position: absolute;
      top: 0;
      width: calc(100% - 4rem);
    }

    .base6 {
      display: flex;
    }

    .download-list {
      align-items: flex-end;
      display: flex;

      li {
        margin-right: 2.5rem;
      }

      a {
        @include variables.line-height(26, 18);
        font-size: 1.8rem;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      i {
        margin-right: 0.5rem;
        position: relative;
        top: -0.1rem;

        svg {
          fill: variables.$projectBlue;
          vertical-align: middle;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .cta {
        width: auto;
      }
    }

    .add-to-calendar {
      @include variables.line-height(26, 18);
      font-size: 1.8rem;
      margin-right: 2.5rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      i {
        margin-right: 0.5rem;
        position: relative;
        top: -0.1rem;

        svg {
          fill: variables.$projectBlue;
          vertical-align: middle;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .events-details {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .events-details {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .events-details {
    &.registration-form {
      .cu-wrapper {
        flex-direction: column;
        .form-container {
          max-width: 100%;
          padding-top: 3em;
        }
      }
    }

    padding: 3rem 0 0;

    @at-root .white-grey-split & {
      margin: 0;
    }

    > div {
      margin: 0;
    }

    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: block;
    }

    .small {
      @include variables.line-height(20, 12);
      font-size: 1.2rem;
    }

    h3 {
      @include variables.line-height(24, 20);
      font-size: 2rem;
    }

    .summary-content {
      padding: 2rem;
    }

    .accordion {
      margin: 0;
      padding: 0;
      width: 100vw;

      > div > div > div > div {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .action-bar {
      margin-top: 0;
      padding: 3rem 2rem 0;

      &::before {
        display: none;
      }

      .cta {
        margin-bottom: 3rem;
      }

      .add-to-calendar {
        display: block;
        margin-bottom: 1.6rem;
      }

      .download-list {
        display: block;

        li {
          display: block;
          margin-bottom: 1.6rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .events-details {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .events-details {
  }
}

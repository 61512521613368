@import "../../styles/variables.scss";

.navigation-manual {
  padding: 5rem 0;
  margin: 5px 0;

  &:not(.full-width) {
    + .navigation-manual {
      &:not(.full-width) {
        padding-top: 0;
        margin-top: -4rem;
      }
    }
  }

  .programme-block {
    display: flex;
    flex-direction: column;
    padding-bottom: 8.5rem;

    .image-wrapper {
      .inner-wrap {
        overflow: hidden;
      }

      img {
        width: 100%;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .programme-info {
      padding: 3rem 3rem 4.5rem;
      width: calc(100% - 2rem);
      position: relative;
      margin-top: -12.2rem;
      z-index: 1;
      height: 100%;
      background: white;

      .inner-wrapper {
        background: white;
      }

      h4 {
        margin-bottom: 1rem;
        a {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      p {
        margin-bottom: 1.5rem;
      }

      & > :global(.cta) {
        position: absolute;
        bottom: 2rem;
        left: 3rem;
      }
    }

    &.no-img {
      .programme-info {
        width: 100%;
        margin-top: 0;
      }
    }

    &.accordion-block {
      .programme-info {
        padding: 3rem 3rem 2.5rem;
        height: auto;
      }

      &.full-width {
        .programme-info {
          padding: 0;
          position: relative;
          margin: 0;
          background: transparent;
          margin-top: 7rem;

          h4,
          p {
            padding: 0 8rem;
          }
        }
      }
    }
  }

  &.full-width {
    .programme-block {
      position: relative;
      display: flex;
      flex-direction: row;

      > div {
        width: 52%;
      }

      .programme-info {
        display: flex;
        align-items: center;
        top: 0;
        margin: 7.4rem 0 -7.4rem -5rem;
        padding: 3rem 8rem 2.5rem;
        height: auto;

        p {
          margin-bottom: 4rem;
        }

        :global(.cta) {
          position: relative;
          bottom: 0;
          left: 0;
        }
      }

      &.flip {
        flex-direction: row-reverse;

        .programme-info {
          margin: 7.4rem -5rem -7.4rem 0;
        }
      }
    }
  }

  &.blue {
    .programme-block {
      .programme-info {
        background-color: $projectDarkBlue;
        color: white;

        .inner-wrapper {
          background-color: inherit;
        }
      }

      :global(.tertiary) svg {
        fill: #ebe8e5;
      }
      :global(.chevron) {
        &::after {
          border-color: white;
        }
      }
    }

    a {
      color: white;
    }

    :global(.cta) {
      // sass-lint:disable placeholder-in-extend
      // @extend .cta.light;
    }
  }
}
// COMPACT WEB
@media #{$compact-web} {
  .navigation-manual {
  }
}
// TABLET PORTRAIT
@media #{$tablet-portrait} {
  .navigation-manual {
    .programme-block {
      &.full-width {
        .programme-info {
          h4 {
            font-size: 2.8rem;
          }

          .inner-wrapper {
            padding-top: 2rem;
          }
        }
      }
    }
  }
}
// MOBILE
@media #{$mobile} {
  .navigation-manual {
    padding: 0;
    margin: 4rem 0;

    .row {
      &.container {
        display: block;
      }
    }

    .programme-block {
      .programme-info {
        height: auto;

        & > :global(.cta) {
          position: relative;
          bottom: 0;
          left: 0;
          text-align: left;
        }
      }

      &.full-width {
        display: block;

        > div {
          width: 100%;
        }

        .programme-info {
          margin-top: -6.2rem;
          width: calc(100% - 2rem);
          display: block;
          position: relative;
          padding: 3rem 3rem 2.5rem;
          min-height: 0;
          height: auto;

          p {
            margin-bottom: 1.5rem;
          }

          .inner-wrapper {
            padding-top: 0;
          }

          :global(.cta) {
            position: relative;
            left: 0;
            bottom: 0;
          }
        }
      }
    }

    &.full-width {
      .programme-block {
        flex-direction: column;
        padding-bottom: 3.5rem;

        > div {
          width: 100%;
        }

        .programme-info {
          height: auto;
          padding: 3rem 3rem 2rem;
          width: calc(100% - 2rem);
          position: relative;
          margin: -12.2rem 0 0;
          z-index: 1;
          min-height: auto;

          .inner-wrapper {
            padding: 0;
          }

          p {
            margin-bottom: 1.5rem;
          }
        }

        &.flip {
          flex-direction: column;

          .programme-info {
            margin: -12.2rem 0 0;
          }
        }
      }
    }
  }

  .swiper {
    > * {
      box-sizing: border-box;
    }
    :global(.swiper-slide) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .programme-block {
      margin-bottom: 0;
    }
  }
}
// MOBILE LANDSCAPE
@media #{$mobile-landscape} {
  .navigation-manual {
  }
}
// MOBILE PORTRAIT
@media #{$mobile-portrait} {
  .navigation-manual {
  }
}

@import "../../../styles/variables.scss";

.cLevel {
  display: block;
  position: absolute;
  left: -2rem;
  top: 80px;
  z-index: 10;
  width: 100%;
  background: white;
  margin-bottom: 10px;
  color: $projectBlue;
  transform: translateX(-100%);
  transition: transform 0.5s;
  padding: 2rem 2rem 0;

  &.open {
    transform: translateX(0);
  }

  ul {
    display: block;

    li {
      &.menuItem {
        position: relative;
        display: block;
        margin-bottom: 0;
        a {
          padding: 10px;
          display: block;
          color: $projectBlue;
          font-size: 1.8rem;
          font-weight: bold;
          width: 100%;
        }
      }

      &.nestItem {
        a {
          font-size: 1.6rem;
          line-height: 18px;
          padding: 2rem 2.5rem 1.5rem 2.5rem;
          font-weight: normal;
        }
      }

      &.folder {
        padding-right: 20px;
        &::before {
          position: absolute;
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          top: calc(50% - 6px);
          right: 0;
          background-size: contain !important;
          background: url("../../../public/image/svg/chevron.svg") center center
            no-repeat;
        }
      }

      &.itemTitle {
        border-bottom: 2px solid #7f8eb0;
        margin: 2rem 0 0;

        a {
          font-size: 1.6rem;
          line-height: 18px;
          padding: 0.5rem 0 1.5rem 0;
          font-weight: 700;
        }
      }
    }
  }

  .btnBack {
    padding: 2rem 4rem;
    display: block;
    border-bottom: 1px solid grey;
    width: calc(100% + 4rem);
    font-size: 1.2rem;
    position: relative;
    margin: -2rem -2rem 0;

    &::before {
      content: "";
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAxZTYyIiB2aWV3Qm94PSIwIDAgOCAxMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC04LjAwMDAwMCwgLTYuMDAwMDAwKSI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhdGgtMSIgcG9pbnRzPSIxMCA2IDguNTkgNy40MSAxMy4xNyAxMiA4LjU5IDE2LjU5IDEwIDE4IDE2IDEyIj48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
      right: 0;
      background-repeat: no-repeat;
      left: 1.5rem;
      top: 50%;
      transform: translateY(-63%) rotate(180deg);
    }
  }

  .goToBtn {
    display: block;
    color: $projectBlue;
    font-size: 1.6rem;
    line-height: 20px;
    font-weight: 400;
    font-family: Montserrat, sans-serif;
    border-bottom: 2px solid #7f8eb0;
    padding-bottom: 1rem;
    margin: 2rem 0 0;
  }

  .cBottom {
    background-color: $projectBlue;
    align-items: center;
    padding: 2rem 2rem;
    margin: 10px -2rem 0;
    &.redLine {
      border-bottom: 0.418rem solid $projectRed;
    }
  }

  .secondaryList {
    li {
      padding: 0.5rem 0;
      margin: 0;

      &:nth-last-child(2) {
        border-bottom: 1px solid white;
      }

      a {
        font-weight: 700;
      }
    }
  }

  .cPromoItems {
    a {
      margin: 0 0 10px;
      display: block;
      text-align: left;
      font-weight: 700;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cPromoCTAs {
    a {
      margin-top: 10px;
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
}

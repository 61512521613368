@use "../../styles/variables";

.dpo-testimonials {
  .section-title {
    margin-bottom: 5.9rem;
  }

  .list-container {
    .row {
      border-top: 0.1rem solid variables.$projectLightGrey;
      border-bottom: 0.1rem solid variables.$projectLightGrey;
      padding-top: 3.6rem;
      padding-bottom: 2rem;
      margin: 0;

      + .row {
        border-top-width: 0;
      }

      .base2 {
        padding-left: 0;
      }
    }

    picture {
      img {
        width: 100%;
      }
    }

    .text {
      h3 {
        color: variables.$projectBlue;
        font-family: variables.$headingFontFamily;
        font-size: 3.6rem;
        line-height: 4.4rem;
        margin-bottom: 1.6rem;
      }

      .cta {
        margin-top: 1.4rem;
        margin-left: 0.7rem;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .dpo-testimonials {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .dpo-testimonials {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .dpo-testimonials {
    .section-title {
      margin-bottom: 2.3rem;
    }

    .list-container {
      .row {
        padding-top: 2.4rem;
        padding-bottom: 1rem;

        .base2,
        .base8 {
          padding-left: 0;
          padding-right: 0;
        }

        picture {
          margin-bottom: 1.6rem;

          img {
            max-width: 130px;
          }
        }

        h3 {
          font-size: 2.8rem;
          line-height: 3.6rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .dpo-testimonials {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .dpo-testimonials {
  }
}

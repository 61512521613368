@use "../../styles/variables";

// COMPONENT - SUBSCRIBE THINK
// DEFAULT CLASS - .subscribe-think
// CLASS VARIANTS -
// WIDE WEB

.subscribe-think {
  color: variables.$projectBlue;

  .spinner {
    display: flex;
    justify-content: center;
  }

  .overlay-content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 100ms;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    overflow-y: scroll;
  }

  &.show {
    .overlay-content {
      visibility: visible;
      opacity: 1;
    }
  }

  .subscribe-screen-content {
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    background: variables.$projectBone;

    .fields-floated {
      .field-row {
        display: block;

        .label {
          label {
            font-weight: inherit;
          }
        }
      }
    }
  }

  .popup {
    margin: 70px auto;
    padding: 3rem;
    background: variables.$projectBone;
    position: relative;
    transition: all 1s ease-in-out;

    // target anchor in rich text
    a {
      text-decoration: underline;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #333;

      &:hover {
        color: #06d85f;
      }
    }

    .content {
      max-height: 30%;
      overflow: auto;
    }

    .left {
      margin-left: unset;
    }

    a {
      line-height: unset;

      &:focus,
      &:hover {
        background-image: none;
      }
    }
  }

  .form-row {
    float: left;
    width: 33.3%;
    padding-right: 1rem;
    margin-bottom: 0;

    .message-wrapper {
      &:before {
        visibility: hidden;
      }
    }
  }

  .form-row.error {
    .form-field {
      .message-wrapper {
        &:before {
          visibility: visible;
        }
      }
    }
  }

  .button-wrapper {
    max-width: 22rem;
    margin-left: auto;
    margin-right: auto;
  }

  a.cancel {
    display: block;
    position: absolute;
    text-decoration: none;
    top: -10px;
    right: 2px;
    color: black;
    height: 30px;
    width: 35px;
    font-size: 500%;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
  }

  .fields-floated {
    display: flex;
  }

  .header-align {
    margin-top: 2rem;
    display: flex;

    svg {
      flex-shrink: 0;
    }
  }

  h4 {
    margin-top: 2rem;
  }

  svg {
    margin-top: 2rem;
    margin-right: 2rem;
  }

  section {
    display: grid;
  }

  .error {
    margin-top: 2rem;
  }

  picture {
    width: 100%;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 4rem;
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .subscribe-think {
  }
}
// MOBILE
@media #{variables.$mobile} {
  .subscribe-think {
    .form-row {
      float: none;
      width: auto;
    }
    .fields-floated {
      flex-direction: column;
    }

    p {
      margin-bottom: 4rem;
    }

    .overlay-content {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .subscribe-think {
  }
}
// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .subscribe-think {
  }
}

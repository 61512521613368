@use "../../styles/variables.scss";
// COMPONENT - C00 Tabs

// DEFAULT CLASS - .js-tablist

// CLASS VARIANTS -

// WIDE WEB

.tabs--my-profile {
  .tabs-item {
    padding: 0 1rem;

    a {
      text-align: center;
      padding: 1rem;
    }
  }
}

.js-tabs {
  position: relative;

  &.tabs-vertical {
    display: flex;

    .js-tablist {
      flex-basis: 20%;
      flex-direction: column;
    }

    .js-tablist-item {
      margin-left: 0;
    }

    .js-tablist-link {
      display: block;
      margin-bottom: 0;
      width: 100%;
    }
  }

  .blue & {
    .js-tablist-item {
      .js-tablist-link {
        color: variables.$projectWhite;

        &[aria-selected="true"] {
          border-bottom-color: variables.$projectInteractiveNavy;
        }
      }
    }
  }

  .left-arrow-el {
    position: absolute;
    top: 1rem;
    left: 1rem;
    pointer-events: none;
    width: 4rem;
    height: 4rem;

    &::before {
      content: "";
      position: absolute;
      border: solid black;
      border-width: 0 0.4rem 0.4rem 0;
      display: inline-block;
      padding: 0.5rem;
      top: 1rem;
      opacity: 0;
      transition: opacity 0.5s;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      left: 0.8rem;
    }
  }

  .right-arrow-el {
    position: absolute;
    top: 1rem;
    right: 1rem;
    pointer-events: none;
    width: 4rem;
    height: 4rem;

    &::after {
      content: "";
      position: absolute;
      border: solid black;
      border-width: 0 0.4rem 0.4rem 0;
      display: inline-block;
      padding: 0.5rem;
      top: 1rem;
      opacity: 0;
      transition: opacity 0.5s;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      right: 0.8rem;
    }
  }

  &.left-arrow {
    position: relative;

    .left-arrow-el {
      &::before {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &.right-arrow {
    position: relative;

    .right-arrow-el {
      &::after {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

.js-tablist {
  border-bottom: 0.1rem solid variables.$baseFontColour;
  display: flex;
}

.js-tablist-item {
  display: inline-block;
  flex: 1;
  margin-bottom: 0;
  margin-left: 0.5rem;

  &:first-child {
    margin-left: 0;
  }
}

.js-tablist-link {
  color: variables.$baseFontColour;
  display: block;
  font-family: variables.$baseFontFamily;
  font-size: 1.6rem;
  font-weight: normal;
  padding: 1rem 2rem;
  text-align: left;
  cursor: pointer;
  height: 100%;
  text-decoration: none;

  &[aria-selected="true"] {
    border-bottom: 0.2rem solid variables.$baseFontColour;
    font-weight: bold;
  }

  &:hover,
  &:focus {
    font-weight: bold;
    text-decoration: none;
  }
}

.js-tabcontent {
  padding: 3.6rem 2.4rem;
  width: 100%;

  &[aria-hidden="true"] {
    display: none;

    &.height-check {
      // sass-lint:disable-block no-important
      height: 0;
      overflow: hidden;
      padding: 0 !important;
      display: block;
    }
  }
}

// MOBILE
@media #{variables.$mobile} {
  .tabs--my-profile {
    .tabs-item {
      padding: 0;

      a {
        padding: 2rem;
        text-align: center;
      }
    }

    .js-tablist {
      border-bottom-width: 0;
    }

    .js-tablist-link {
      background-color: transparent;
      color: variables.$projectBlue;

      &[aria-selected="true"] {
        background-color: variables.$projectBlue;
        color: variables.$projectWhite;
        font-weight: 500;
        border-bottom-width: 0;
      }
    }
  }
  .js-tabs {
    &.tabs-vertical {
      flex-wrap: wrap;

      .js-tablist {
        flex-basis: 100%;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .js-tablist-item {
        flex-basis: auto;
      }
    }

    .blue & {
      .js-tablist-item {
        .js-tablist-link {
          color: variables.$projectWhite;

          &[aria-selected="true"] {
            border-bottom-color: variables.$projectInteractiveNavy;
          }
        }
      }
    }
  }

  .js-tablist {
    flex-direction: column;
  }

  .js-tablist-item {
    border-bottom: 0.1rem solid variables.$projectSantaGray;
    flex-basis: 100%;
    margin-bottom: 0;
    margin-left: 0;
  }
}

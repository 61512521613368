@use "../../styles/variables.scss";

.comparison-overlay {
  color: white;
  position: fixed;
  bottom: -20rem;
  left: 0;
  width: 100%;
  background: variables.$projectDarkBlue;
  z-index: 1;
  padding: 2.5rem 0 1.5rem;
  box-shadow: 0 0 2.9rem 0 rgba(0, 0, 0, 0.18);
  transition: bottom 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  &.has-items {
    bottom: 0;
  }

  .toggle-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;

    &[data-open="true"] {
      svg {
        transform: rotate(-90deg);
      }
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 0;
    }

    .icon-chevron {
      border: 1px solid transparent;
      border-radius: 50%;
      width: 3.6rem;
      height: 3.6rem;
      padding: 0.7rem 0.5rem;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      fill: white;
      transform: rotate(90deg);
      width: 1rem;
      display: block;
    }

    &:hover,
    &:focus {
      outline: 0;

      .icon-chevron {
        border-color: white;
      }
    }
  }

  .collapse-wrapper {
    overflow: hidden;
    transition: height 0.5s;
    height: 0;

    .inner-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .programmes-wrapper {
        display: flex;
        max-width: 75rem;
        flex-wrap: wrap;
        width: 100%;
      }

      .show-alt-text {
        .programme-blank {
          .title {
            display: none;

            &.two {
              display: block;
            }
          }
        }
      }
    }

    .programme,
    .programme-blank {
      display: flex;
      align-items: center;
      padding: 1.1rem 2rem;
      width: 35.4rem;
      margin: 0.8rem 3.7rem 0.8rem 0;
      justify-content: space-between;
      border: 1px solid white;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

      .inner-wrapper {
        font-size: 1.4rem;
      }
    }

    .programme-blank {
      border: 1px dotted white;

      .plus-icon {
        width: 1rem;
        height: 1rem;
        position: relative;

        &::after,
        &::before {
          position: absolute;
          content: "";
          background: white;
          width: 1px;
          height: 100%;
          left: 50%;
          top: 0;
          transform: translate(-50%, 0);
        }

        &::after {
          width: 100%;
          height: 1px;
          left: auto;
          transform: translate(0, -50%);
          top: 50%;
        }
      }

      &.hide {
        display: none;
      }
    }

    .remove-block {
      > span {
        background-image: none;
        width: 1rem;
        height: 1rem;
        display: block;
        cursor: pointer;

        svg {
          width: 1rem;
          height: 1rem;
          display: block;
          fill: variables.$projectBlue;
        }
      }
    }

    .programme {
      background: white;
      color: variables.$projectBlue;

      .inner-wrapper {
        font-family: variables.$headingFontFamily;
      }

      &:hover {
        background: transparent;
        color: white;

        .remove-block {
          > span {
            svg {
              fill: white;
            }
          }
        }
      }
    }

    .button-wrapper {
      margin-left: 3.8rem;

      [disabled="true"] {
        opacity: 0;
      }
    }
  }
}

// LESS THAN MAX WIDTH
@media #{variables.$less-than-max-width} {
  .comparison-overlay {
    > .inner-wrapper {
      padding: 0 2rem;
    }

    .collapse-wrapper {
      .programme,
      .programme-blank {
        width: 47%;
        margin-right: 4%;
        flex-basis: 47%;

        &:nth-child(2n + 2) {
          margin-right: 0;
        }

        @media #{variables.$ie11-styles} {
          max-width: 47%;
        }
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .comparison-overlay {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .comparison-overlay {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .comparison-overlay {
    .toggle-wrapper {
      pointer-events: auto;
      text-align: center;

      .icon-chevron {
        margin-bottom: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0.2rem 0 0;

        &:hover,
        &:focus {
          border-color: transparent;
        }
      }
    }

    .collapse-wrapper {
      .inner-wrapper {
        flex-direction: column;

        .button-wrapper {
          margin-left: 0;
          margin-top: 1.2rem;
        }

        .programmes-wrapper {
          flex-direction: column;
          max-width: none;
          width: 100%;

          .programme-blank,
          .programme {
            width: 100%;
            margin: 0.7rem 0;

            .inner-wrapper {
              font-size: 1.2rem;
            }

            @media #{variables.$ie11-styles} {
              max-width: none;
            }
          }
        }
      }
    }
  }
}

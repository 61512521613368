@use "../../styles/variables";

.navigational-events-listing {
  margin: 0 -2rem;
  padding-bottom: 6.8rem;
  position: relative;

  .wrapper-wide {
    margin: 0;
    width: 100%;
  }

  &.blue {
    .wrapper-heading {
      background-color: variables.$projectBlue;
    }

    .wrapper-articles {
      position: relative;

      &::before {
        content: "";
        background-color: variables.$projectBlue;
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      > .wrapper {
        position: relative;
        z-index: 1;
      }
    }

    .context-label,
    .headline,
    .supporting-copy {
      color: variables.$projectWhite;
    }

    .supporting-copy {
      p {
        color: variables.$projectWhite;
      }
    }
  }

  .context-label {
    display: block;
    font-family: variables.$baseFontFamily;
    font-size: 1.4rem;
    font-weight: normal;
    letter-spacing: 0.88px;
    margin-bottom: 0.8rem;
    margin-top: 4.9rem;
    text-transform: uppercase;
  }

  .headline {
    font-family: variables.$headingFontFamily;
    font-size: 7.6rem;
    font-weight: 700;
    margin-bottom: 0.4rem;
    line-height: 1.25;
  }

  .supporting-copy {
    margin-bottom: 4.8rem;

    p {
      font-family: variables.$baseFontFamily;
      font-size: 1.6rem;
      font-weight: normal;
    }
  }

  .wrapper {
    padding: 0 2rem;

    &-articles {
      margin-bottom: 5.7rem;
    }
  }

  .cta-container {
    margin-top: 5rem;
  }

  .header-image {
    img {
      width: 100%;
    }
  }

  .event {
    &-wrapper {
      display: flex;
      flex-direction: row;

      .event-item {
        width: 100%;

        .promotional-event {
          height: 100%;
          background-color: variables.$projectWhite;
          margin-bottom: 0;

          article {
            width: 100%;
          }

          .text {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            height: 100%;

            .footer {
              margin-top: auto;
            }
          }
        }
      }

      &[data-items="3"] {
        .event-item {
          width: 33.3%;

          &:nth-child(2) {
            margin: 0 3.8rem;
          }
        }
      }

      &[data-items="2"] {
        .event-item {
          width: 50%;

          &:first-child {
            margin-right: 1.9rem;
          }

          &:last-child {
            margin-left: 1.9rem;
          }
        }
      }
    }
  }

  .row-middle {
    .base3 {
      margin: 0 auto;
      width: 100%;
    }
  }

  .promotional-event {
    .wrapper {
      padding: 0;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .navigational-events-listing {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .navigational-events-listing {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .navigational-events-listing {
    .wrapper-wide {
      .wrapper {
        padding: 0 2.5rem;
      }
    }

    .context-label {
      margin-top: 3.4rem;
      margin-bottom: 1.6rem;
    }

    .headline {
      font-size: 5.5rem;
      line-height: 6.5rem;
      margin-bottom: 0.3rem;
    }

    .supporting-copy {
      p {
        font-size: 1.9rem;
      }
    }

    .event {
      &-wrapper {
        .event-item {
          margin: 0 0.5rem;
        }

        &[data-items="3"] {
          .event-item {
            &:nth-child(2) {
              margin: 0 0.5rem;
            }
          }
        }

        &[data-items="2"] {
          .event-item {
            width: 50%;

            &:first-child {
              margin-right: 0.25rem;
            }

            &:last-child {
              margin-left: 0.25rem;
            }
          }
        }
      }
    }

    .wrapper {
      &-articles {
        margin-bottom: 2.6rem;

        .wrapper {
          padding-right: 0;
        }
      }
    }

    .row-middle {
      .base3 {
        width: 90%;

        .cta {
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }

    .tns-outer {
      display: flex;
      flex-direction: column-reverse;
      margin-left: -2.5rem;

      .tns-nav {
        margin-top: 2rem;
        text-align: center;
        width: 100%;

        button {
          position: relative;
          width: 1.2em;
          height: 1.2rem;
          padding: 1rem;
          background: none;
          border: 0;
          pointer-events: none;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 0.1rem solid variables.$projectBlue;
            background-color: transparent;
            width: 1.2em;
            height: 1.2rem;
            padding: 0;
          }

          &.tns-nav-active {
            &::after {
              background-color: variables.$projectBlue;
            }
          }
        }
      }
    }

    .promotional-event {
      .wrapper {
        padding: 0;
      }
    }
  }
}

@use "../../styles/variables.scss";
// COMPONENT - Header 1.0

// DEFAULT CLASS - .site-header

// CLASS VARIANTS -

// WIDE WEB

.skip {
  left: -999rem;
  position: absolute;

  &:active,
  &:focus {
    background: #01a7e3;
    top: 0;
    left: 0;
    color: #ffffff;
    font-size: 1.6rem;
    display: inline-block;
    padding: 0.4rem;
    border: 0.1rem solid variables.$projectWhite;
    white-space: nowrap;
    text-align: center;
    font-weight: 700;
  }
}

.site-header {
  background-color: variables.$projectBlue;
  color: white;
  padding: 0 2rem;

  > .wrapper {
    position: relative;
  }

  a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: normal;
    position: relative;

    &.cta {
      font-weight: bold;
    }
  }

  nav {
    &.top {
      border-bottom: 1px solid #383860;
      display: flex;
      line-height: 1.5;

      a {
        &:hover,
        &:focus {
          background-image: linear-gradient(
            90deg,
            variables.$projectInteractiveNavy,
            variables.$projectInteractiveNavy
          );
          background-repeat: no-repeat;
          background-position: 0 100%;
          background-size: 100% 0.1rem;
          padding-bottom: 0.4rem;
        }
      }

      ul {
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: 5rem;
        opacity: 1;

        li {
          &.icon {
            // position: absolute;
            right: 0;

            &:nth-last-child(2) {
              margin-right: 0;
            }

            &:nth-last-child(3) {
              right: 1.8rem;
            }
          }
        }
      }

      svg {
        width: 1.8rem;
        height: 1.8rem;
        fill: white;
      }
    }

    ul {
      display: flex;

      li {
        line-height: 1.75;
        margin: 1rem 2.5rem 1rem 0;

        &.icon {
          margin: 1rem 1.5rem 1rem 0;

          &.mobile-btn {
            display: none;
          }

          a {
            &:hover,
            &:focus {
              background: none;

              svg {
                fill: variables.$projectInteractiveNavy;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &.mobile {
        display: none;
      }
    }
  }

  .main {
    display: flex;
    align-items: center;
    padding: 0.5rem 0 0;

    a {
      font-size: 1.6rem;
      position: relative;

      &:hover,
      &:focus,
      &.active {
        background-image: linear-gradient(
          90deg,
          variables.$projectInteractiveNavy,
          variables.$projectInteractiveNavy
        );
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: 100% 0.2rem;
        padding-bottom: 0.8rem;
      }
    }

    nav {
      margin-left: auto;
      width: 100%;

      ul {
        justify-content: flex-end;
      }
    }

    .logo {
      margin-left: -1rem;
      a {
        display: inline-block;

        &:hover,
        &:focus {
          background: none;
          padding: 0;
        }
      }
      .image {
        width: 9.2rem;
        height: 9.2rem;
      }
    }
  }

  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: -2rem;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;

    &.hide {
      visibility: hidden;
      height: 0;
      opacity: 0;
    }
  }

  &.light {
    background-color: variables.$projectBone;

    nav {
      &.top {
        border-bottom: 0;

        svg {
          fill: variables.$projectBlue;
        }
      }
    }

    a {
      color: variables.$projectBlue;
    }

    .main {
      padding: 0 0 5rem;

      .logo {
        margin-left: 0;
      }

      nav {
        margin-top: 5.75rem;
      }
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
}

// TABLET PORTRAIT
@media #{variables.$tablet} {
  .site-header {
    nav {
      &.top {
        background-color: variables.$projectDarkBlue;
        width: calc(100% + 4rem);
        padding: 0 2rem;
        margin-left: -2rem;
        border-bottom: 0;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }

        ul {
          li {
            &:nth-last-child(4) {
              margin-right: 0;
            }

            &:nth-last-child(3) {
              margin-right: 4rem;
            }

            &:nth-last-child(2) {
              margin-right: 0;
            }

            &.icon {
              position: absolute;
              right: 2rem;
              top: 8rem;
            }
          }
        }
      }
    }

    .main {
      flex-direction: column;
      align-items: normal;

      .logo {
        margin-right: auto;

        img {
          width: 8rem;
        }
      }

      nav {
        margin-left: 0;

        ul {
          justify-content: space-between;

          li {
            text-align: center;
            margin: 1rem;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }

    &.light {
      nav {
        &.top {
          background-color: variables.$projectBlue;

          a {
            color: white;
          }
        }
      }

      .main {
        margin-top: 1rem;
        padding: 0 0 2rem;

        nav {
          margin-top: 0;
        }
      }
    }
  }
}

@media #{variables.$mobile} {
  .site-header {
    position: relative;

    nav {
      &.top {
        border-bottom: 0;

        svg {
          width: 2rem;
          height: 2rem;
        }

        .mobile-btn {
          margin-top: 0.2rem;

          a {
            &[aria-label="Menu"] {
              padding: 0;
            }
          }

          svg {
            height: 3.4rem;
            margin-top: 0.1rem;
            width: 4rem;
          }
        }

        ul {
          li {
            display: none;

            &:nth-last-child(3) {
              margin-right: 6.9rem;
            }

            &:nth-last-child(2) {
              margin-right: 4rem;
            }

            &:last-child {
              margin-right: -1.1rem;
            }

            &.icon {
              display: block;
              position: absolute;
              right: 2rem;
              top: 50%;
              transform: translateY(-50%);
              margin-top: 0;

              &:nth-last-child(2) {
                margin-right: 2.9rem;
              }

              a {
                display: block;
                padding: 1.1rem;
              }
            }
          }
        }
      }
    }

    .main {
      flex-direction: column;
      align-items: normal;
      padding: 0.75rem 0;

      .logo {
        margin-right: auto;
        .image {
          width: 6.2rem;
          height: 6.2rem;
        }
      }

      nav {
        visibility: hidden;
        position: absolute;
        top: 80px;
        left: -2rem;
        padding: 2rem;
        background-color: white;
        z-index: 3;
        transform: translateX(-100%);
        transition:
          transform 0.5s,
          visibility 0.5s;
        transition-delay: visibility 0.5s;

        &.open {
          transform: translateX(0);
          visibility: visible;
        }

        ul {
          flex-direction: column;

          li {
            text-align: left;
            margin-left: 0;
            margin: 1rem 0;
            position: relative;

            svg {
              position: absolute;
              width: 3.2rem;
              height: 3.2rem;
              right: 0;
              padding: 1rem;
              background-repeat: no-repeat;
              top: 50%;
              transform: translateY(-50%);
              fill: variables.$projectDarkBlue;
            }

            a {
              font-size: 1.8rem;
              display: block;
              font-weight: bold;
              width: 100%;

              svg {
                margin-left: 8px;
              }

              // &::after {
              //   content: "";
              //   position: absolute;
              //   width: 1.2rem;
              //   height: 1.2rem;
              //   background-image: url("data:image/svg+xml;charset%3DUS-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20fill%3D%22%23001e62%22%20viewBox%3D%220%200%208%2012%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20transform%3D%22translate(-8.000000%2C%20-6.000000)%22%3E%0A%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22path-1%22%20points%3D%2210%206%208.59%207.41%2013.17%2012%208.59%2016.59%2010%2018%2016%2012%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E");
              //   right: 1rem;
              //   background-repeat: no-repeat;
              //   top: 50%;
              //   transform: translateY(-50%);
              // }
            }
          }

          &.mobile {
            display: block;
          }

          &.secondary {
            background-color: variables.$projectBlue;
            margin: 0 -2.05rem -3rem;
            padding: 2rem;

            li {
              a {
                font-size: 1.2rem;
                color: white;
                display: inline;

                &::after {
                  content: none;
                }
              }

              &:last-child {
                padding-top: 0.2rem;
                border-top: 1px solid white;
              }
            }
          }
        }

        a {
          color: variables.$projectBlue;
        }
      }
    }

    .overlay {
      width: 100vw;
      height: 100vh;
      position: absolute;
      left: -2rem;
      background-color: rgba(0, 0, 0, 0.7);
      display: block;

      &.hide {
        visibility: hidden;
        height: 0;
        opacity: 0;
      }
    }

    &.light {
      nav {
        &.top {
          ul {
            li {
              &.icon {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .main {
        margin-top: 0;
        margin-bottom: 0;
        padding: 1rem 0;
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
}

.search {
  background: #001440;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  visibility: hidden;

  &.show {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  .inside {
    width: 100%;
    max-width: 1140px;
    padding: 2rem;
  }

  .helper {
    color: variables.$projectInteractiveNavy;
    font-size: 1.2rem;
  }

  .input {
    padding-bottom: 1rem;
    border-bottom: 1px solid variables.$projectInteractiveNavy;
    margin-bottom: 6rem;
    position: relative;
    margin-top: 3rem;
  }

  input {
    &[type="text"] {
      background: 0;
      border: 0;
      color: variables.$projectInteractiveNavy;
      font-size: 4.8rem;
      font-family: "GT-Sectra-Fine-Bold", serif;

      &::placeholder {
        color: variables.$projectInteractiveNavy;
      }
    }
  }

  button {
    border: 0;
    background: 0;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding-right: 0.1rem;

    svg {
      fill: white;
      width: 3rem;
      height: 3rem;
    }

    &:hover,
    &:focus {
      svg {
        fill: variables.$projectInteractiveNavy;
      }
    }
  }

  .links {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 2rem;

      li {
        margin-right: 10rem;
        margin-bottom: 2rem;
      }
    }

    .cta {
      font-weight: bold;
    }
  }

  .close {
    width: 2rem;
    margin-left: 4rem;
    cursor: pointer;
    font-size: 0;
    height: 1.6rem;
    right: 3rem;
    top: 2rem;
    position: absolute;

    &:active,
    &:focus,
    &:hover {
      svg {
        fill: variables.$projectInteractiveNavy;
      }
    }

    svg {
      fill: #fff;
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}

// COMPACT WEB
@media #{variables.$compact-web} {
  .search {
  }
}

// TABLET PORTRAIT
@media #{variables.$tablet-portrait} {
  .search {
  }
}

// MOBILE
@media #{variables.$mobile} {
  .bg-mobile-menu {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .search {
    .inside {
      max-width: 100%;
      padding: 2rem;

      input {
        &[type="text"] {
          font-size: 2.8rem;
        }
      }
    }
  }
}

// MOBILE LANDSCAPE
@media #{variables.$mobile-landscape} {
  .search {
  }
}

// MOBILE PORTRAIT
@media #{variables.$mobile-portrait} {
  .search {
  }
}
